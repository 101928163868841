const VersionModule = {
    state: () => ({
        unternehmensportal: {},
        informationsportal: {},
        config: {},
        externalConfig: {}
    }),
    mutations: {
        setUnternehmensportalVersion(state, item) {
            state.unternehmensportal = item;
        },
        setInformationsVersion(state, item) {
            state.informationsportal = item;
        },
        config(state, item) {
            state.config = item
        }
    },
    getters: {
        unternehmensportalVersion(state) {
            return state.unternehmensportal;
        },
        informationsportalVersion(state) {
            return state.informationsportal;
        },

        config(state) {
            let tmp = JSON.stringify(state.config)
            return JSON.parse(tmp)
        },

        externalConfig(state) {
            let tmp = JSON.stringify(state.externalConfig)
            return JSON.parse(tmp)
        }
    },
    actions: {
        setUnternehmensportalVersion({ commit }, item) {
            commit('setUnternehmensportalVersion', item);
        },

        setInformationsVersion({ commit }, item) {
            commit('setInformationsVersion', item);
        },

        config({ commit }, item) {
            commit('config', item)
        }
    }
}

export default VersionModule