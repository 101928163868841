import axios from 'axios'

let apiUnternehmensPortal = axios.create({
  headers: {
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json; charset=UTF-8",
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
  },
  baseURL: "",
})

let apiInformationsPortal = axios.create({
  headers: {
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json; charset=UTF-8",
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
  },
  baseURL: "",
})

let apiDataExchange = axios.create({
  headers: {
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json; charset=UTF-8",
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
  },
  baseURL: "",
})

export { apiUnternehmensPortal, apiInformationsPortal, apiDataExchange }