export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "header": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
    "backtomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website of Lower Saxony"])},
    "backtomainlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lower-saxony.de/startseite/"])},
    "ministry": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministries"])},
      "chancellery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State Chancellery"])},
      "interiorAndSport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of the Interior and Sports"])},
      "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Finance"])},
      "socialAffairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Social Affairs, Health, and Gender Equality"])},
      "scienceAndCulture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Science and Culture"])},
      "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Culture"])},
      "economics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Economics, Labor, Transport, and Digitisation"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Food, Agriculture, and Consumer Protection"])},
      "justice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Justice"])},
      "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Environment, Energy, and Climate Protection"])},
      "federal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Federal and European Issues, and Regional Development"])}
    },
    "service": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceportal Lower Saxony"])}
    },
    "serviceAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceaccount"])},
    "navigation": {
      "company": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Entrepreneurs"])},
        "listOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneurs assistant"])},
        "listTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Recognition"])},
        "listThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulated Professions"])},
        "listFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the Point of Single Contact"])},
        "listFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Applications"])}
      },
      "authorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Authorities"])},
      "simpleLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple Language"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Selection"])}
    }
  },
  "informationsWithSameClusterTitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thematically related information material"])},
  "postalcodeComponent": {
    "postalcodesearch": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey Rene, please add the postalcodesearch translation. Thank you"])}
    },
    "informationportalentry": {
      "nationwide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationwide"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the district"])}
    }
  },
  "servicepage": {
    "serviceSearch": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual search"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter one or more keywords related to your search."])},
      "expression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expression:"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. business registration"])},
      "searchBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "searchExpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current search term: "])},
      "selectedByCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection by categories"])}
    }
  },
  "detailpage": {
    "backToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Search"])},
    "printBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "service": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicservices"])}
    },
    "information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information portal"])}
    }
  },
  "sidebar": {
    "topten": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 10-Publicservices for companies"])}
    },
    "navoLink": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directly to the online application:"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for electronic application NAVO"])}
    },
    "contactinformation": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower Saxony Ministry of Food, Agriculture and Consumer Protection"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "openingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
      "noneContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact information included"])},
      "streetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calenberger Str. 2"])},
      "federalState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30169 Hannover"])},
      "telNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+49 (0) 511 120 0"])},
      "faxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+49 (0) 511 120 2385"])}
    }
  },
  "popup": {
    "acceptBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "rejectBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "breadcrumb": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "publicservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicservices"])},
    "informationportal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information portal"])},
    "dataExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Exchange"])},
    "uploadDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "simpleLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple Language"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error page"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])}
  },
  "landingPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service website of Lower Saxony"])},
    "contextText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the service website of Lower Saxony you will find information and services of the administration. Whether you want to renew your identity card or start a company – the lower Saxony service website answers the most important questions and supports you around your request."])},
    "contextTextPartTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "contextTextPartThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "contextTextPartFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "listOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which authority in my area is responsible?"])},
    "listTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When did it open and how do I reach it?"])},
    "listThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I get my request done online?"])},
    "listFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What documents do I need?"])},
    "listFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What else do I need to consider?"])},
    "link1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneurs assistant"])},
    "link2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsportal"])},
    "warning": {
      "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENTION!"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current information on coronavirus in Lower Saxony can be found here:"])}
    },
    "linkBlockCompany": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Entrepeneurs"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found, lead, conclude – here you get all the relevant information, find your contacts, and carry out necessary formalities online."])}
    },
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
    "linkBlockSimpleLanguage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple Language"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem Ipsum"])}
    }
  },
  "content": {
    "service": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicservices"])},
      "description_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_1"])},
      "description_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_2"])},
      "list": {
        "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_list_1"])},
        "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_list_2"])},
        "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_list_3"])},
        "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_list_4"])},
        "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_list_5"])},
        "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_list_6"])}
      }
    },
    "information": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information portal"])},
      "description_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english_description_one"])}
    }
  },
  "contentBottom": {
    "company": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Entrepreneurs"])},
      "listOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneurs assistant"])},
      "listTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Recognition"])},
      "listThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulated Professions"])},
      "listFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the Point of Single Contact"])},
      "listFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Applications"])}
    },
    "authorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Authorities"])},
    "simpleLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple Language"])}
  },
  "faq": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Lorem Ipsum</b>"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Lorem Ipsum</p>"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "imprint": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])}
  },
  "contact": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum:"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum:"])}
  },
  "privacy": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy - DSGVO"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])}
  },
  "accessibility": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum"])}
  },
  "footer": {
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "toTheTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top of the Page"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])}
  },
  "simpleLanguage": {
    "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
  },
  "errorPage": {
    "404": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found!"])}
    },
    "503": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["503"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service is unavailable!"])}
    }
  }
}