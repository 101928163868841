const UserFeedbackModule = {
  state:{
    toast:{
      isActive: false,
      color: "info",
      summary: 'Infotitle',
      message: "Infomessage",
      timeout: 2000
    }
  },

  mutations:{
    // Sets just the state of the user feedback
    setIsUserFeedback(state, value){
      state.toast.isActive = value;
    },

    showUserFeedback(state, feedback){
      state.toast = feedback
      state.toast.isActive = true
    }
  },

  actions:{
    setIsUserFeedback({ commit }, status){
      commit('setIsUserFeedback', status)
    },
    showUserFeedback({ commit }, feedback){
      commit('showUserFeedback', feedback)
    }
  },

  getters: {
    getShowToast(state) {
        return state.toast
    },
  },
  
}

export default UserFeedbackModule

