const LoggedInCompanyModule = {
  state: {
    loggedInCompany: {}
  },
  mutations: {
    setLoggedInCompany(state, payload) {
      state.loggedInCompany = payload;
    }
  },
  actions: {
    setLoggedInCompany({ commit }, data) {
      commit('setLoggedInCompany', data);
    },
  },
  getters: {
    getLoggedInCompany: (state) => state.loggedInCompany,
    isCompanyLoggedIn: (state) => !!state.loggedInCompany
  }
};

export default LoggedInCompanyModule;