const ClusterModule = {
  state: {
    clusters: [],
    counter: 0,
    searchResultsCounter: 0,
    searchAttempt: 0,
    isSearching: false,
  },

  mutations: {
    setItems(state, items) {
      state.clusters = items;

      if (state.searchAttempt === 0 && state.isSearching) {
        state.searchResultsCounter = state.clusters.count;
        state.searchAttempt++;
      }
    },

    save(state, item) {
      state.clusters.clusters.push(Object.assign({}, item));
      // Clone the array to trigger a UI update
      state.clusters.clusters = [...state.clusters.clusters];
      
    },

    delete(state, item) {
      const index = state.clusters.clusters.findIndex(
        (cluster) => cluster.id === item.id
      );
      state.clusters.clusters.splice(index, 1);
      // Clone the array to trigger a UI update
      state.clusters.clusters = [...state.clusters.clusters];
    },

    update(state, item) {
      const existsAtIndex = state.clusters.clusters.findIndex(
        (cluster) => cluster.id === item.id
      );
      // Replace the object in array
      state.clusters.clusters[existsAtIndex] = item;
      // Clone the array to trigger a UI update
      state.clusters.clusters = [...state.clusters.clusters];
    },

    resetSearch(state) {
      state.searchResultsCounter = 0;
      state.searchAttempt = 0;
      state.isSearching = false;
    },

    isSearching(state, item) {
      state.isSearching = item;
    },

    setSearchAttempt(state, item){
        state.searchAttempt = item;
    }
  },
  getters: {
    clusters(state) {
      return state.clusters.clusters;
    },

    getCluster: (state) => (id) => {
      return state.clusters.clusters.find((cluster) => cluster.id === id);
    },

    searchResultsCounter: (state) => {
      return state.searchResultsCounter;
    },

    clustersCount: (state) => {
      return state.clusters?.count
    },
  },
  actions: {
    setItems({ commit }, items) {
      commit("setItems", items);
    },
    save({ commit }, item) {
      commit("save", item);
    },
    delete({ commit }, item) {
      commit("delete", item);
    },
    update({ commit }, item) {
      commit("update", item);
    },
  },
};

export default ClusterModule;
