const MappingPublicserviceToClusterModule = {
    state: () => ({
        publicServicesToClusters: [],
        counters: {},
        searchInput: '',
        publicservice: {}
    }),
    mutations: {
        setItems(state, items) {
            state.publicServicesToClusters = items;
        },

        setCounters(state, items) {
            state.counters = items;
        },

        setPublicservice(state, item) {
            state.publicservice = item;
        },
    },
    getters: {
        publicServicesToClusters(state) {
            return state.publicServicesToClusters.mappingPSToClusterDtos;
        },

        publicServices: (state) => (index) => {
            return state.publicServicesToClusters.mappingPSToClusterDtos[index].publicServiceDtos
        },

        counters(state) {
            return state.counters
        },

        publicservice(state) {
            let tmp;
            if (state.publicservice.mappingPSToClusterDtos) {
                try {
                    tmp = state.publicservice.mappingPSToClusterDtos.shift().publicServiceDtos.shift()
                } catch (error) {
                    tmp = null
                }
            }
            return tmp
        }
    },
    actions: {
        setItems({ commit }, items) {
            commit('setItems', items);
        },

        setCounters({ commit }, items) {
            commit('setCounters', items);
        },

        setPublicservice({ commit }, item) {
            commit('setPublicservice', item);
        },
    }
}

export default MappingPublicserviceToClusterModule