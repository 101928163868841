<template>
	<Header />
	<main>
		<Breadcrumb />
		<router-view />
		<ContentBottom />
	</main>
	<Footer />
</template>

<script>
import Header from "@/components/header/Header.vue";
import Breadcrumb from "@/components/content/Breadcrumb.vue";
import ContentBottom from "@/components/content/ContentBottom.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
    metaInfo: {
        htmlAttrs: { lang: "de" },
        meta: [
            { charset: "utf-8" },
            {
                name: "viewport",
                content: "width=device-width, initial-scale=1",
            },
        ],
    },

    components: {
        Header,
        Breadcrumb,
        ContentBottom,
        Footer,
    },

    mounted(){
        window.scrollTo(0, 0)
    }
};
</script>

<style lang="scss">
@import "~primevue/resources/primevue.min.css";
@import "~primeicons/primeicons.css";
@import '~primeflex/primeflex.css';
@import "./assets/mdc-light-indigo/theme.css";

#app {
	background: initial !important;
	width: 988px;
	margin: 0 auto 0 auto;
	padding: 0 10px;
}

body,
input,
button,
select,
option,
.p-component {
	font-family: 'OpenSansRegular', sans-serif;
	font-size: 14px;
}

:focus-visible {
	outline: 1px solid black;
}

.mail-link {
	color: #5f5e5e;
}

.link-style {
	color: #5f5e5e;
}

/*
	Override Primefaces styles 
*/
.p-autocomplete-dd {
	--size: 1.625rem;
	height: var(--size);
	.p-autocomplete-dropdown {
		width: var(--size);
		padding: 0;
	}
}
</style>