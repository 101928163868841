<template>
  <div class="accessibility-site">
    <div class="md:col-12 sm:col-12">
      <div class="flex flex-column accessibility-content">

        <h1 class="headline">{{ $t('footer.accessibility') }}</h1>

        <strong class="subheadline">{{ $t('accessibility.title1') }}</strong>
        <span v-html="$t('accessibility.text1')" />

        <strong class="subheadline">{{ $t('accessibility.title2') }}</strong>
        <span v-html="$t('accessibility.text2')" />

        <strong class="subheadline">{{ $t('accessibility.title3') }}</strong>
        <span v-html="$t('accessibility.text3')" />

        <strong class="subheadline">{{ $t('accessibility.title4') }}</strong>
        <span v-html="$t('accessibility.text4')" />

        <strong class="subheadline">{{ $t('accessibility.title5') }}</strong>
        <span v-html="$t('accessibility.text5')" />

        <strong class="subheadline">{{ $t('accessibility.title6') }}</strong>
        <span v-html="$t('accessibility.text6')" />

        <strong class="subheadline">{{ $t('accessibility.title7') }}</strong>
        <span v-html="$t('accessibility.text7')" />

        <strong class="subheadline">{{ $t('accessibility.title8') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <p>
            Über folgenden Kontakt können Sie Mängel in Bezug auf die Einhaltung der Barrierefreiheitsanforderungen mitteilen: 
            <a href="mailto:pressestelle@ml.niedersachsen.de" class="mail-link">pressestelle@ml.niedersachsen.de</a>
            bzw. 
            <a href="mailto:unternehmensportal@ml.niedersachsen.de" class="mail-link">unternehmensportal@ml.niedersachsen.de</a>
          </p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim ab dolorum perferendis tenetur itaque unde. 
            Laudantium, nemo quas. Officiis minus quas dolorem, itaque non fuga a odit quia explicabo nobis.</p>
        </span>

        <strong class="subheadline">{{ $t('accessibility.title9') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <p>
            Bei nicht zufriedenstellenden Antworten aus oben genannter Kontaktmöglichkeit können Sie bei der Schlichtungsstelle, 
            eingerichtet bei der Landesbeauftragten für Menschen mit Behinderungen in Niedersachsen, 
            einen Antrag auf Einleitung eines Schlichtungsverfahrens nach dem Niedersächsischen 
            Behindertengleichstellungsgesetz (NBGG) stellen.
          </p>
          <p>
            Die Schlichtungsstelle nach § 9 d NBGG hat die Aufgabe, 
            Streitigkeiten zwischen Menschen mit Behinderungen und öffentlichen Stellen des Landes Niedersachsen, 
            zum Thema Barrierefreiheit in der IT, beizulegen. Das Schlichtungsverfahren ist kostenlos. 
            Es muss kein Rechtsbeistand eingeschaltet werden.
          </p>
          <p>
            Direkt kontaktieren können Sie die Schlichtungsstelle unter:
          </p>
          <p>
            Telefon: +49 (0) 511 120 4010,
            E-Mail: <a href="mailto:schlichtungsstelle@ms.niedersachsen.de" class="mail-link">schlichtungsstelle@ms.niedersachsen.de</a>
          </p>
          <p>
            Datum der Veröffentlichung der Website: März 2024.
          </p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim ab dolorum perferendis tenetur itaque unde. 
            Laudantium, nemo quas. Officiis minus quas dolorem, itaque non fuga a odit quia explicabo nobis.</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accessibility",

  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
  .accessibility-site{
    margin-left: 15px;
    margin-bottom: 50px;
  }
}
.accessibility-site{
  text-align: justify;
  .accessibility-content *:not(.headline) {
    line-height: 1.5em !important;
  }
}
p{
  word-wrap: break-word;
  max-width: 68ch;
}
.subheadline{
  margin-bottom: 15px;
  margin-top: 15px;
}
.link-style{
  text-decoration: underline;
}
.bullet-text{
  padding-bottom: 10px;
  word-wrap: break-word;
  max-width: 68ch;
  text-align: justify;
}

.ul-bullet-text{
  padding-left: 1rem;
  max-width: 68ch !important;
}
</style>

