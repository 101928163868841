<template>
  <div class="footer group" role="navigation">
    <ul>
      <li>
        <span class="a">{{$t('contentBottom.company.name')}}</span>
        <div class="navigation">
          <router-link class="link-navigation" to="/Verwaltungsleistungen">
            {{
              $t("header.navigation.company.listOne")
            }}
          </router-link>
          <router-link class="link-navigation" to="/InformationPortal">
            Informationsportal
          </router-link>
          <router-link class="link-navigation" to="/managementPortal">
            Management
          </router-link>
        </div>
      </li>

      <li v-show="$i18n.locale === 'de-DE'">
        <router-link to="/LeichteSprache">
        <span
          >{{$t('contentBottom.simpleLanguage')}}</span
        >
        </router-link>
      </li>
      <li>
        <router-link to="/FAQ">
          <span>FAQ</span>
        </router-link>
      </li>
    </ul>
    <div class="section logorow">
      <a href="//www.niedersachsen.de" title="Link zur Startseite"
        ><img
          src="@/assets/niedersachsen.png"
          width="222"
          height="55"
          class="ndslogo"
          alt="Niedersachsen Logo"
      /></a>
      <span class="klar blink"
        ><img src="@/assets/klar.png" alt="Niedersachen klar Logo"
      /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterGroup",
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 20px 0 50px 0;
  margin-top: 60px;
  clear: both;
  border-top: 1px dotted #484848;
  position: relative;
  z-index: 500;

  a:hover {
    text-decoration: underline;
  }

  li {
    padding-bottom: 5px;
    a {
      color: #484848;
    }
    li a {
      color: #484848;
      font-size: 0.85em;
    }
  }
}

.footer > ul > li > a {
  min-height: 2em;
  display: block;
  padding-bottom: 10px;
}

.footer > ul > li:last-child {
  padding-right: 0;
  width: 14%;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.footer > ul > li {
  float: left;
  display: block;
  position: relative;
  cursor: pointer;
  width: 16.3%;
  padding-right: 1%;
}

.footer > ul > li span.a {
  min-height: 2.75em;
  display: block;
  padding-bottom: 10px;
  color: #484848;
  cursor: auto;
}

.external_link{
  padding-left: 20px;
}

.external_link:before {
  content: "";
  background-image: url(https://service.niedersachsen.de/img/extern-link.svg);
  position: absolute;
  left:0;
  width: 15px;
  height: 15px;
}



.navigation{
  display: flex;
  flex-direction: column;
  text-align: justify;

  .link-navigation{
    margin-bottom: 5px;
  }
}
</style>