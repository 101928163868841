import CrudApi from "./crudApi";

class PrincipalApi extends CrudApi{

    async userInformation(){
        this.apiInformationsPortal.get(this.apiName + `/getUserInfo`, this.store.state.keycloak.jwt).then(response=>{
            this.store.dispatch(`${this.module}/setUser`, response.data)
        }).catch((error)=>{
            this.errorHandling(this.module, error.response);
        })
    }

}

export const principalClient = new PrincipalApi("/api/infoportal/v1/principal","principal");
