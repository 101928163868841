const DataExchangeModule = {
    state: {
      uploadResults: {
        message: '',
        successfulFiles: [],
        failedFiles: []
      }
    },
    mutations: {
      setUploadResults(state, { message, successfulFiles, failedFiles }) {
        state.uploadResults.message = message;
        state.uploadResults.successfulFiles = successfulFiles;
        state.uploadResults.failedFiles = failedFiles;
      }
    },
    actions: {
      setUploadResults({ commit }, uploadResults) {
        commit('setUploadResults', uploadResults);
      }
    },
    getters: {
      getUploadResults: (state) => state.uploadResults,
      getUploadMessage: (state) => state.uploadResults.message,
      getSuccessfulFiles: (state) => state.uploadResults.successfulFiles,
      getFailedFiles: (state) => state.uploadResults.failedFiles
    }
  };
  
  export default DataExchangeModule;
  