const organizationUnitModule = {
  state: {
    organizationUnits: [],
    counter: 0,

    searchResultsCounter: 0,
    searchAttempt: 0,
    isSearching: false,
  },

  mutations: {
    setItems(state, items) {
      state.organizationUnits = items.organizationUnits;

      if (state.isSearching) {
        state.searchResultsCounter = items.count;
        state.searchAttempt++;
      }
    },

    save(state, item) {
      state.organizationUnits.push(Object.assign({}, item));
      // Clone the array to trigger a UI update
      state.organizationUnits = [...state.organizationUnits];
    },

    delete(state, item) {
      const index = state.organizationUnits.findIndex(
        (organizationUnit) => organizationUnit.id === item.id
      );
      state.organizationUnits.splice(index, 1);
      // Clone the array to trigger a UI update
      state.organizationUnits = [...state.organizationUnits];
    },

    update(state, item) {
      const existsAtIndex = state.organizationUnits.findIndex(
        (organizationUnit) => organizationUnit.id === item.id
      );
      // Replace the object in array
      state.organizationUnits[existsAtIndex] = item;
      // Clone the array to trigger a UI update
      state.organizationUnits = [...state.organizationUnits];
    },

    setCounter(state, item) {
      state.counter = item;
    },

    resetSearch(state){
      state.searchResultsCounter = 0;
      state.searchAttempt = 0;
      state.isSearching = false;
    },

    isSearching(state, item){
      state.isSearching = item;
    }
  },
  getters: {
    organizationUnits(state) {
      return state.organizationUnits;
    },

    getOrganizationUnit: (state) => (id) => {
      return state.organizationUnits.find(
        (organizationUnit) => organizationUnit.id === id
      );
    },

    counter(state) {
      return state.counter;
    },

    searchResultsCounter: (state) => {
      return state.searchResultsCounter;
    },
  },
  actions: {
    setItems({ commit }, items) {
      commit("setItems", items);
    },
    save({ commit }, item) {
      commit("save", item);
    },
    delete({ commit }, item) {
      commit("delete", item);
    },
    update({ commit }, item) {
      commit("update", item);
    },
    setCounter({ commit }, item) {
      commit("setCounter", item);
    },
  },
};

export default organizationUnitModule;
