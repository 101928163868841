import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import FAQ from '../views/FAQ.vue'
import Impressum from '../views/Impressum.vue'
import Contact from '../views/Contact.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Accessibility from '../views/Accessibility.vue'
import store from '../store-modules'
import { CookieJar } from 'tough-cookie';
const cookieJar = new CookieJar();

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Unternehmensportal - Niedersachsen',
      breadcrumb: { label: 'breadcrumb.home', path: '/' }
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Verwaltungsleistungen',
    alias: '/publicservices',
    name: 'ServicePage',
    meta: {
      title: 'Unternehmensportal - Verwaltungsleistung',
      breadcrumb: { label: 'breadcrumb.publicservice', path: '/Verwaltungsleistungen' }
    },
    component: () => import('../components/content/ContentLandingpage.vue'),
    props: {
      contentType: 'service'
    }
  },
  {
    path: '/Verwaltungsleistungen/:objectName',
    alias: '/Publicservices/:objectName',
    name: 'service',
    meta: {
      title: 'Verwaltungsleistung - Detail',
      breadcrumb: [
        { label: 'breadcrumb.publicservice', path: '/Verwaltungsleistungen' }
      ],
    },
    component: () => import('../components/content/Detailpage.vue'),
    props: (route) => ({
      ...route.params
    },
      { contentType: 'service' }
    )
  },
  {
    path: '/managementPortal',
    name: 'ManagementPortal',
    meta: {
      title: 'Unternehmensportal - Managementportal',
      breadcrumb: { label: 'Managementportal', path: '/managementPortal' },
      isAuthenticated: true,
    },
    component: () => import('../components/content/management/ManagementPortal.vue')
  },
  {
    path: '/InformationPortal',
    name: 'InformationPortal',
    meta: {
      title: 'Unternehmensportal - Informationsportal',
      breadcrumb: { label: 'breadcrumb.informationportal', path: '/InformationPortal' },
    },
    component: () => import('../components/content/ContentLandingpage.vue'),
    props: {
      contentType: 'information'
    }
  },
  {
    path: '/dataExchange',
    name: 'DataExchange',
    meta:{
      title: 'Unternehmensportal - Datenaustausch',
      breadcrumb: { label: 'breadcrumb.dataExchange', path: '/dataExchange' }
    },
    component: () => import('../views/DataExchange.vue')
  },
  {
    path: '/upload-documents',
    name: 'UploadDocuments',
    meta:{
      title: 'Unternehmensportal - Bereitstellung von Dokumenten',
      breadcrumb: { label: 'breadcrumb.uploadDocuments', path: '/upload-documents' }
    },
    component: () => import('../views/UploadDocuments.vue')
  },
  {
    path: '/InformationPortal/:objectName/:objectId',
    name: 'information',
    meta: {
      title: 'Informationsportal - Detail',
      breadcrumb: [
        { label: 'breadcrumb.informationportal', path: '/InformationPortal' },
      ],
    },
    component: () => import('../components/content/Detailpage.vue'),
    props: (route) => ({
      ...route.params
    },
      { contentType: 'information' }
    )
  },
  {
    path: '/FAQ',
    name: 'faq',
    component: FAQ,
    meta: {
      title: 'Unternehmensportal - FAQ',
      breadcrumb: { label: 'FAQ', path: '/FAQ' }
    },
  },
  {
    path: '/LeichteSprache',
    alias: '/SimpleLanguage',
    name: 'SimpleLanguage',
    meta: {
      title: 'Leichte Sprache',
      breadcrumb: { label: 'breadcrumb.simpleLanguage', path: '/LeichteSprache' },

    },
    component: () => import('../components/content/SimpleLanguage.vue'),
  },
  {
    path: '/Impressum',
    name: 'impressum',
    component: Impressum,
    meta: {
      title: 'Impressum',
      breadcrumb: { label: 'breadcrumb.imprint', path: '/Impressum' }
    },
  },
  {
    path: '/Kontakt',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Kontakt',
      breadcrumb: { label: 'breadcrumb.contact', path: '/Kontakt' }
    },
  },
  {
    path: '/Datenschutz',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'Datenschutzerklärung',
      breadcrumb: { label: 'breadcrumb.privacy', path: '/Datenschutz' }
    },
  },
  {
    path: '/Barrierefreiheit',
    name: 'accessibility',
    component: Accessibility,
    meta: {
      title: 'Barrierefreiheit',
      breadcrumb: { label: 'breadcrumb.accessibility', path: '/Barrierefreiheit' }
    },
  },
  // and finally the default route, when none of the above matches:
  {
    path: "/Fehlerseite404", name: 'Error404Page', component: () => import('../components/content/errorpage/Error404Page.vue'),
    meta: {
      breadcrumb: { label: 'breadcrumb.error', path: '/Fehlerseite404' }
    },
    props: true,
  },
  {
    path: '/Fehlerseite503', name: 'Error503Page', component: () => import('../components/content/errorpage/Error503Page.vue'),
    meta: {
      breadcrumb: { label: 'breadcrumb.error', path: '/Fehlerseite503' }
    }
  }
  ,
  // and finally the default route, when none of the above matches:
  {
    path: '/:pathMatch(.*)*', name: 'Error503Page', component: () => import('../components/content/errorpage/Error503Page.vue'),
    meta: {
      breadcrumb: { label: 'breadcrumb.error', path: '/Fehlerseite503' }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function authorized(authorizedRoles, roles) {
  let isAuthorized = false;

  authorizedRoles.forEach(auth => {
    isAuthorized = roles.includes(auth)
  })
  return isAuthorized;
}

function initializeKeycloak() {
  // Get the actual url of the app, it's needed for Keycloak
  let basePath = window.location.origin.toString();

  setTimeout(() => {
    const keycloak = store.state.keycloak.keycloak;
    const keycloakConfig = store.getters["version/config"];

    keycloak.init({ onLoad: 'login-required', checkLoginIframe: false, redirectUri: `${basePath}/managementPortal` }).then((auth) => {
      if (!auth) {
        window.location.reload()
      } else {

        if (authorized(keycloakConfig.rolesFachadministrator, keycloak.realmAccess.roles) || authorized(keycloakConfig.rolesSystemadministrator, keycloak.realmAccess.roles)) {

          store.state.keycloak.jwt = { headers: { 'Authorization': `Bearer ${keycloak.token}` }, jar: cookieJar, withCredentials: true };
          localStorage.setItem("access_token", keycloak.token);

          if (keycloak.isTokenExpired) {
            keycloak.updateToken(70)
              .then(() => {
                localStorage.setItem("access_token", keycloak.token);
              })
              .catch(err => {
                console.error(err)
              })
          }
        } else {

          keycloak.logout({ redirectUri: `${basePath}` })
          alert('Sie verfügen nicht über die Berechtigungen für diesen Bereich!')
        }
      }
    })
  }, 1000)
}

router.beforeEach((to, from, next) => {

  if (to.meta.isAuthenticated) {
    initializeKeycloak();
  }

  // This page did not require authentication
  // Routing Service ID
  if (from.params.servicID) {
    next({ name: to.params.name, serviceID: from.params.serviceID })
  } else {
    // Remove the service/information detail page from the breadcrumb
    if (from.name === 'service' || from.name === 'information') {
      from.meta.breadcrumb.pop();
    }
    next();
  }
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router
