<template>
  <div class="imprint-site">
    <div class="md:col-12 sm:col-12">
      <div class="flex flex-column imprint-content">

        <h1 class="headline">{{ $t('footer.imprint') }}</h1>

        <strong class="subheadline">{{ $t('imprint.title1') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <p>Niedersächsisches Ministerium<br>
          für Ernährung, Landwirtschaft und<br>
          Verbraucherschutz</p>
          <p>
            Calenberger Straße 2<br>
            30169 Hannover<br>
            Telefon: +49 (0) 511 120 2095/2135/2136/2137<br>
            Telefax: +49 (0) 511 120 2382<br>
            E-Mail: <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
          </p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          <p>Lower Saxony State Chancellery<br>
          Niedersächsische Staatskanzlei</p>
          <p>
            Calenberger Straße 2<br>
            30169 Hannover<br>
            Telefon: +49 (0) 511 120 2095/2135/2136/2137<br>
            Telefax: +49 (0) 511 120 2382<br>
            E-Mail: <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
          </p>
        </span>
        <br>
        <strong class="subheadline">{{ $t('imprint.title2') }}</strong>
        <p>{{ $t('imprint.text2') }}</p>
        <br>
        
        <strong class="subheadline">{{ $t('imprint.title3') }}</strong>
        <span v-html="$t('imprint.text3')" />
        <br>

        <strong class="subheadline">{{ $t('imprint.title4') }}</strong>
        <span v-html="$t('imprint.text4')" />
        <br>

        <strong class="subheadline">{{ $t('imprint.title5') }}</strong>
        <span v-html="$t('imprint.text5')" />
        <br>

        <strong class="subheadline">{{ $t('imprint.title6') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <p>Behördlicher Datenschutzbeauftragter<br>
          Calenberger Straße 2<br>
          30169 Hannover<br>
          Telefon: +49 (0) 511 120 2305<br>
          E-Mail: <a :href="'mailto:Datenschutz@ml.niedersachsen.de'" class="mail-link">Datenschutz@ml.niedersachsen.de</a></p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          E-Mail: <a :href="'mailto:Datenschutz@ml.niedersachsen.de'" class="mail-link">Datenschutz@ml.niedersachsen.de</a>
        </span>
        <br>

        <strong class="subheadline">{{ $t('imprint.title7') }}</strong>
        <span v-html="$t('imprint.text7')" />
        <br>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Impressum",

  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
  .imprint-site{
    margin-left: 15px;
    margin-bottom: 50px;
  }
}
.imprint-site{
  text-align: justify;
  .imprint-content *:not(.headline) {
    line-height: 1.5em !important;
  }
}
p{
  word-wrap: break-word;
  max-width: 68ch;
}
.subheadline{
  margin-bottom: 15px;
  margin-top: 15px;
}
.link-style{
  text-decoration: underline;
}
</style>

