<template>
  <footer class="footer-main">
    <div class="footerContent">
      <div class="footerStart">
        <router-link to="/Impressum">
          {{ $t("footer.imprint") }}
        </router-link>
        <router-link to="/Datenschutz">
          {{ $t("footer.privacy") }}
        </router-link>
        <router-link to="/Kontakt">
          {{ $t("footer.contact") }}
        </router-link>
        <router-link to="/Barrierefreiheit">
          {{ $t("footer.accessibility") }}
        </router-link>
        <router-link to="/FAQ">
          FAQ
        </router-link>
      </div>

      <div class="footerEnd" tabindex="0" @keydown.enter="toTheTop" @click="toTheTop">
        <div class="totop">{{ $t("footer.toTheTop") }}</div>
      </div>
    </div>
    <div class="footerVersion">
      <span v-if="$route.name === 'ManagementPortal' && unternehmensportal">Unternehmensportalversion: {{unternehmensportal.version}} </span>
      <span v-if="$route.name === 'ManagementPortal' && informationsportal">Infomationsportalversion: {{informationsportal.version}} </span>
       <span v-if="$route.name === 'ManagementPortal'">Frontendversion: {{ frontendversion}} </span>
    </div>
  </footer>
</template>

<script>
import { versionClient } from "@/api/version.js";
export default {
  name: "Footer",

  data() {
    return {
      isMobileCss: false,
      unternehmensportal: null,
      informationsportal: null,
    };
  },

  methods: {
    toTheTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },

  computed:{
    frontendversion(){
      return process.env.VUE_APP_VERSION
    }
  },

  watch: {
    async '$route.name'() {
      if (this.$route.name === 'ManagementPortal') {
        await versionClient.unternehmensportalVersion();
        await versionClient.informationsportalVersion();
        this.unternehmensportal = this.$store.getters['version/unternehmensportalVersion'];
        this.informationsportal = this.$store.getters['version/informationsportalVersion'];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/footer.scss";

@media screen and (max-width: 960px) {
  .footer-main{
    display: none !important;
  }
}
</style>