const PrincipalModule = {
    state: {
        users: [],
        user:{}
    },

    mutations: {
        setItems(state, items) {
            state.users = items;
        },

        save(state,item){    
            state.users.push(Object.assign({},item))
        },

        delete(state, item){
            const index = state.users.findIndex(user => user.id === item.id);
            state.users.splice(index, 1);
        },

        update(state, item){
            const existsAtIndex = state.users.findIndex(user => user.id === item.id);
            // Replace the object in array
            state.users[existsAtIndex] = item
            // Clone the array to trigger a UI update
            state.users = [...state.users]
        },

        setUser(state,item){   
            state.user = Object.assign({},item);
        },
    },
    getters: {
        users(state) {
            return state.users
        },

        user(state){
            return state.user;
        },

        roles(state){
            return state.user.roles;
        }
    },
    actions: {
        setItems({ commit }, items) {
            commit('setItems', items);
        },
        save({commit},item){
            commit('save',item)
        },
        delete({commit},item){
            commit('delete', item)
        },
        update({commit},item){
            commit('update', item)
        },
        setUser({commit},item){
            commit('setUser', item)
        }
    }
}

export default PrincipalModule