const KeycloakModule = {
    state: () => ({
        keycloak: {},
        jwt: {}
    }),
    mutations: {
        keycloak(state, item){   
            state.keycloak =  item
        }
    },
    getters: {
        keycloak(state){
            let tmp = JSON.stringify(state.keycloak)
            return JSON.parse(tmp)
        }
    },
    actions: {
        keycloak({commit}, item){
            commit('keycloak', item)
        }
    }
}

export default KeycloakModule