<template>
  <div class="faq-site">
    <div class="md:col-12 sm:col-12">
      <div class="flex flex-column faq-content">

        <h1 class="headline">FAQ</h1>

          <span class="subheadline" v-html="$t('faq.title1')" />
          <span v-html="$t('faq.text1')" />

          <span class="subheadline" v-html="$t('faq.title2')" />
          <span v-html="$t('faq.text2')" />

          <span class="subheadline" v-html="$t('faq.title3')" />
          <span v-html="$t('faq.text3')" />

          <ul class="bullet-point-width">
            <li>
              <span class="subheadline" v-html="$t('faq.title4')" />
              <span v-html="$t('faq.text4')" />
            </li>
            <li>
              <span class="subheadline" v-html="$t('faq.title5')" />
              <span v-html="$t('faq.text5')" />
            </li>
            <li>
              <span class="subheadline" v-html="$t('faq.title6')" />
              <span v-html="$t('faq.text6')" />
            </li>
          </ul>    

          <span class="subheadline" v-html="$t('faq.title7')" />
          <span v-html="$t('faq.text7')" />

          <span class="subheadline" v-html="$t('faq.title8')" />
          <span v-html="$t('faq.text8')" />

          <span class="subheadline" v-html="$t('faq.title9')" />
          <span v-html="$t('faq.text9')" />

          <span class="subheadline" v-html="$t('faq.title10')" />
          <span v-html="$t('faq.text10')" />

          <span class="subheadline" v-html="$t('faq.title11')" />
          <span v-show="$i18n.locale === 'de-DE'">
            <p>Die gesuchte Beschreibung der Verwaltungsleistung ist eventuell unter anderen Stichworten im System enthalten. 
            Verwenden Sie ggf. andere Begriffe, um die von Ihnen gesuchte Verwaltungsleistung zu finden.</p>
            <p>Falls Sie nichts finden, können Sie per Kontaktformular weitere Auskünfte erfragen oder sich direkt an
            <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
            wenden.</p>
            <p>Hierzu sollten Sie kurz Ihr Anliegen beschreiben. Bitte haben Sie Verständnis dafür, 
            dass wir nur Auskünfte über die dafür zuständigen Stellen erteilen können. 
            Es können für Sie keine Antragsverfahren direkt durchgeführt oder Ihre Nachrichten weitergeleitet werden. 
            Direkte Verfahrensabwicklungen sind nicht möglich. Es kann auch keine Rechtsbeihilfe geleistet werden.</p>
          </span>
          <span v-show="$i18n.locale === 'en-EN'">
            Lores impressum
            <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
          </span>
          
          <span class="subheadline" v-html="$t('faq.title12')" />
          <span v-show="$i18n.locale === 'de-DE'">
            <p>Wir erheben und verarbeiten Ihre Daten entsprechend den Vorgaben und Regelungen des deutschen bzw. 
              europäischen Datenschutzrechtes. Ihre Daten werden nur für Ihr Anliegen genutzt, 
              eine Weitergabe dieser Daten an Dritte erfolgt nicht. Die Übertragung und Speicherung 
              Ihrer Daten erfolgt im Unternehmensportal ausschließlich verschlüsselt.</p>
              
            <p>Genaue Informationen zum Datenschutz finden Sie
              <router-link to="/Datenschutz">
                <span class="un-link">hier.</span>
              </router-link></p>
          </span>
          <span v-show="$i18n.locale === 'en-EN'">
            Lores impressum
            <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
          </span>

          <span class="subheadline" v-html="$t('faq.title13')" />
          <span v-show="$i18n.locale === 'de-DE'">
            <p>Wenn Sie einen Fehler (z. B. Rechtschreibfehler, veraltete Informationen, fehlerhafter Link) gefunden haben, 
            wenden Sie sich bitte per E-Mail an <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a>. 
            Bitte beschreiben Sie den Fehler so genau wie möglich und fügen Sie ggf. einen Screenshot und/oder einen Link auf die fehlerhafte Seite 
            hinzu. Ihre Nachricht wird dann an die zuständige Stelle weitergeleitet.</p>
          </span>
          <span v-show="$i18n.locale === 'en-EN'">
            Lores impressum
            <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
          </span>

          <span class="subheadline" v-html="$t('faq.title14')" />
          <span v-show="$i18n.locale === 'de-DE'">
            <p>Um Fragen, Anregungen oder Feedback zum Unternehmensportal mitzuteilen, 
            schreiben Sie uns gerne eine E-Mail an 
            <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a>.</p>
          </span>
          <span v-show="$i18n.locale === 'en-EN'">
            Lores impressum
            <a :href="'mailto:unternehmensportal@ml.niedersachsen.de'" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> 
          </span>
        
          
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",

  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
  .faq-site{
    margin-left: 15px;
    margin-bottom: 50px;
  }
}
.bullet-point-width{
  max-width: 68ch !important;
}
.faq-site{
  text-align: justify;
  .faq-content *:not(.headline) {
    line-height: 1.5em !important;
  }
}
p{
  word-wrap: break-word;
  max-width: 68ch;
}
.mail-link{
  text-decoration: underline;
}

.un-link{
  color: #5f5e5e;
  text-decoration: underline;
}

.subheadline{
  margin-bottom: 15px;
  margin-top: 15px;
  word-wrap: break-word;
  max-width: 68ch;
}
</style>