const PublicServiceModule = {
  state: () => ({
    publicServices: [],
    topTen: [],
    searchInput: null,
    publicService: {},
    searchResultsCounter: 0,
    searchAttempt: 0,
    isSearching: false,
  }),
  mutations: {
    setItems(state, items) {
      state.publicServices = items;

      if (state.searchAttempt === 0 && state.isSearching) {
        state.searchResultsCounter = state.publicServices.count;
        state.searchAttempt++;
      }
    },

    setService(state, item) {
      state.publicService = item;
    },

    setTopTen(state, items) {
      state.topTen = items;
    },

    save(state, item) {
      state.publicServices.services.push(Object.assign({}, item));
      // Clone the array to trigger a UI update
      state.publicServices.services = [...state.publicServices.services];
    },

    delete(state, item) {
      const index = state.publicServices.services.findIndex(
        (service) => service.id === item.id
      );
      state.publicServices.services.splice(index, 1);
      // Clone the array to trigger a UI update
      state.publicServices.services = [...state.publicServices.services];
    },

    update(state, item) {
      const existsAtIndex = state.publicServices.services.findIndex(
        (service) => service.id === item.id
      );
      // Replace the object in array
      state.publicServices.services[existsAtIndex] = item;
      // Clone the array to trigger a UI update
      state.publicServices.services = [...state.publicServices.services];
    },
    searchItems(state, items) {
      state.publicServices.services = items;
    },
    resetSearch(state) {
      state.searchResultsCounter = 0;
      state.searchAttempt = 0;
      state.isSearching = false;
    },

    isSearching(state, item) {
      state.isSearching = item;
    },

    setSearchAttempt(state, item){
        state.searchAttempt = item;
    }
  },
  getters: {
    publicServices(state) {
      if (state.publicServices.services) {
        const regex = new RegExp(state.searchInput, "i");
        return state.publicServices.services.filter(
          (service) =>
            service.name.match(regex) ||
            service.englishName.match(regex) ||
            !state.searchInput
        );
      }
    },

    publicServicesPublished(state) {
      let stateOnlyPublished = [];
      for (let publicService of state.publicServices.services) {
        if (
          publicService.isPublished === true ||
          publicService.published === true
        ) {
          stateOnlyPublished.push(publicService);
        }
      }
      return stateOnlyPublished;
    },

    getService: (state) => {
      return state.publicService;
    },

    service: (state) => (id) => {
      let foundService;
      if (state.publicServices.services) {
        foundService = state.publicServices.services.find(
          (service) => service.id === id
        );
      }
      return foundService;
    },

    publicService: (state) => (name) => {
      let foundService;
      if (state.publicService.services) {
        foundService = state.publicService.services.find(
          (service) => service.name === name || service.englishName
        );
      }
      return foundService;
    },

    topTen(state) {
      return state.topTen;
    },

    searchResultsCounter: (state) => {
      return state.searchResultsCounter;
    },
  },
  actions: {
    setItems({ commit }, items) {
      commit("setItems", items);
    },
    setService({ commit }, item) {
      commit("setService", item);
    },
    setTopTen({ commit }, items) {
      commit("setTopTen", items);
    },
    save({ commit }, item) {
      commit("save", item);
    },
    delete({ commit }, item) {
      commit("delete", item);
    },
    update({ commit }, item) {
      commit("update", item);
    },
    searchItems({ commit }, items) {
      commit("searchItems", items);
    },
  },
};

export default PublicServiceModule;
