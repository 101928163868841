const TagModule = {
    state: {
        tags: [],
    },

    mutations: {
        setItems(state, items) {
            state.tags = items.tags.map(item => {
                item.name = item.name.toUpperCase();
                return item;
            });
        },

        save(state, item) {
            state.tags.push(Object.assign({}, item))
        },

        delete(state, item) {
            const index = state.tags.findIndex(tag => tag.id === item.id);
            state.tags.splice(index, 1);
        },

        update(state, item) {
            const existsAtIndex = state.tags.findIndex(tag => tag.id === item.id);
            // Replace the object in array
            state.tags[existsAtIndex] = item
            // Clone the array to trigger a UI update
            state.tags = [...state.tags]
        }
    },
    getters: {
        tags(state) {
            return state.tags
        },
        getTag: (state) => (id) => {
            return state.tags.find(tag => tag.id === id)
        }
    },
    actions: {
        setItems({ commit }, items) {
            commit('setItems', items);
        },
        save({ commit }, item) {
            commit('save', item)
        },
        delete({ commit }, item) {
            commit('delete', item)
        },
        update({ commit }, item) {
            commit('update', item)
        }
    }
}

export default TagModule