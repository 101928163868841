/**
 * Generates an object with operation parameters for API calls.
 *
 * @param {Object} options - The options for generating the parameters.
 * @returns {Object} The generated parameter object.
 */
export function managementApiParams(options = {}) {
  const {
    isSearching = false,
    searchInput = "",
    page = 1,
    rows = 10,
    tableIsPublished,
    sortOrder = "asc",
    sortField = ""
  } = options;

  return {
    ...(isSearching && { isSearching }),
    searchInput, // Include searchInput if it's not empty
    locale: "DE", // Default language setting
    page: page, // Current page for pagination
    rows: rows, // Number of rows per page
    ...(typeof tableIsPublished !== "undefined" && {
      isPublished: tableIsPublished,
    }), // Include isPublished if it's explicitly provided
    sortOrder, // Sort order, either 'asc' or 'desc'
    ...(sortField && {sortField})
  };
}
