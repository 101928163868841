<template>
  <div class="privacy-site">
    <div class="md:col-12 sm:col-12">
      <div class="flex flex-column privacy-content">

        <h1 class="headline">{{ $t('footer.privacy') }}</h1>

        <strong class="subheadline">{{ $t('privacy.title1') }}</strong>
        <span v-html="$t('privacy.text1')" />

        <strong class="subheadline">{{ $t('privacy.title2') }}</strong>

        <span v-show="$i18n.locale === 'de-DE'">
          <p class="text-underline">Verantwortlicher für die Datenverarbeitung:</p>

          <p>
            Niedersächsisches Ministerium<br> 
            für Ernährung, Landwirtschaft und<br>
            Verbraucherschutz<br>
            Calenberger Str. 2<br>
            30169 Hannover<br>
            Telefon: +49 (0) 511 120 0<br>
            Telefax: +49 (0) 511 120 2385<br>
            E-Mail: <a href="mailto:poststelle@ml.niedersachsen.de" class="mail-link">poststelle@ml.niedersachsen.de</a>
          </p>

          <p class="text-underline">Auftragsverarbeiter:</p>

          <p>
            Landesbetrieb IT.Niedersachsen (IT.N)<br>
            Göttinger Chaussee 209<br>
            30459 Hannover<br>
            Telefon: +49 (0) 511 120 0<br>
            Telefax: +49 (0) 511 120 4901<br>
            E-Mail: <a href="mailto:poststelle@it.niedersachsen.de" class="mail-link">poststelle@it.niedersachsen.de</a>
          </p>

          <p class="text-underline">Kontaktdaten des/der Datenschutzbeauftragten:</p>
          
          <p>
            Niedersächsisches Ministerium<br> 
            für Ernährung, Landwirtschaft und<br> 
            Verbraucherschutz<br>
            Datenschutzbeauftragte/r<br>
            Calenberger Str. 2<br>
            30169 Hannover<br>
            E-Mail: <a href="mailto:datenschutz@ml.niedersachsen.de" class="mail-link">datenschutz@ml.niedersachsen.de</a>
          </p>

          <p>
            Um Kommunikation im Internet zu ermöglichen, müssen Daten von dem Server, auf dem sie gespeichert sind, 
            zu dem angeschlossenen Computer gelangen, der auf die Inhalte zugreifen möchte. Hierzu muss mit der Anfrage, 
            die Daten abzurufen, auch mitgeteilt werden, an welchen Empfänger die Information vom Server übermittelt werden soll. 
            Zu diesem Zwecke sind allen mit dem Internet verbundenen Geräten sog. IP-Adressen zugeordnet. 
            Die Übermittlung der IP-Adresse erfolgt beim Aufruf einer Internetseite automatisch durch den Browser des Nutzers. 
            Zu diesem Zweck werden die IP-Adressen der Nutzer, die die Webseite des Niedersächsischen Ministeriums für Ernährung, 
            Landwirtschaft und Verbraucherschutz aufrufen, sowie die durch den Browser übermittelten Daten durch das 
            Niedersächsische Ministerium für Ernährung, Landwirtschaft und Verbraucherschutz verarbeitet.
          </p>
          <p>
            Die Verarbeitung erfolgt zur Erfüllung der Aufgaben nach Art. 6 Abs. 1 lit. a), c) und 
            lit. e) in Verbindung mit Art. 57 DSGVO. Die Daten werden entsprechend der rechtlichen Vorgaben gespeichert.
          </p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim ab dolorum perferendis tenetur itaque unde. 
            Laudantium, nemo quas. Officiis minus quas dolorem, itaque non fuga a odit quia explicabo nobis.</p>
        </span>

        <strong class="subheadline">{{ $t('privacy.title3') }}</strong>
        <span v-html="$t('privacy.text3')" />

        <strong class="subheadline">{{ $t('privacy.title4') }}</strong>
        <span v-html="$t('privacy.text4')" />

        <strong class="subheadline">{{ $t('privacy.title5') }}</strong>
        <span v-html="$t('privacy.text5')" />

        <strong class="subheadline">{{ $t('privacy.title6') }}</strong>
        <span v-html="$t('privacy.text6')" />

        <strong class="subheadline">{{ $t('privacy.title7') }}</strong>
        <span v-html="$t('privacy.text7')" />

        <strong class="subheadline">{{ $t('privacy.title8') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <p>
            Jede betroffene Person hat das Recht auf Auskunft nach Art. 15 DSGVO, das Recht auf Berichtigung nach Art. 
            16 DSGVO und das Recht auf Löschung nach Art. 17 DSGVO. Darüber hinaus besteht ein Beschwerderecht bei einer 
            Datenschutzaufsichtsbehörde.
          </p>
          <p class="text-underline">Recht auf Auskunft:</p>
          <p>
            Es besteht ein Recht auf Auskunft seitens des Verantwortlichen über die erhobenen personenbezogenen Daten 
            sowie auf Berichtigung unrichtiger Daten. Die Löschung der Daten kann im Rahmen des Art. 17 DSGVO beantragt werden, 
            z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden. Es besteht zudem das Recht auf Einschränkung 
            der Verarbeitung, wenn eine der in Art. 18 DSGVO genannten Voraussetzungen vorliegt. Ein Recht auf Datenübertragbarkeit besteht 
            in den Fällen des Art. 20 DSGVO.
          </p>
          <p>
            Weiterhin besteht das Recht auf Auskunft über die Verarbeitung von personenbezogenen Daten 
            zu Ihrer Person in der Antragsverwaltung oder in der zuständigen Behörde und an welche Stellen diese Daten im Rahmen 
            des Verwaltungsverfahrens übermittelt werden.
          </p>
          <p>
            Um den Antragsteller eindeutig identifizieren zu können, bitten wir Sie hierfür um die Stellung einer schriftlichen Anfrage.
          </p>
          <p>
            Grundsätzlich ist eine Auskunft nicht kostenpflichtig. Auskunfts- und Informationsbegehren sind in der Regel unverzüglich, 
            innerhalb eines Monats nach Eingang der Anfrage zu bearbeiten. Die Frist kann um zwei Monate verlängert werden, 
            soweit dies unter Berücksichtigung der Komplexität und/oder der Anzahl der Anfragen erforderlich ist. Im Fall einer 
            Fristverlängerung werden wir Sie rechtzeitig informieren.
          </p>
          <p>
            Eine sichere Kommunikation mit Kommunikationspartnern außerhalb des Landesdatennetzes ist derzeit nicht möglich, 
            deshalb werden aus datenschutzrechtlichen Gründen personenbezogene Daten nicht per E-Mail übersandt. 
            Wenn Sie jedoch darauf bestehen, dass Ihnen der Ausdruck Ihrer bei uns gespeicherten personenbezogenen Daten per E-Mail 
            übersandt wird, benötigen wir dafür von Ihnen eine schriftliche Zustimmung/Legitimation.
          </p>
          <p class="text-underline">Recht auf Berichtigung:</p>
          <p>
            Sie haben die Möglichkeit, die von Ihnen bei der Anmeldung oder Registrierung angegebenen Daten selbständig zu berichtigen. 
            Dabei können die notwendigen Stammdaten (Mindestangaben) korrigiert und optionale Angaben geändert und gelöscht werden.
          </p>
          <p>
            Eine nachträgliche Korrektur bereits übermittelter Anträge ist nicht möglich.
          </p>
          <p class="text-underline">Recht auf Löschung:</p>
          <p>
            Sie haben die Möglichkeit, die von Ihnen freiwillig angegebenen Daten (optionale Angaben) zu löschen.
          </p>
          <p>
            Des Weiteren haben Sie das Recht, die Löschung der Sie betreffenden personenbezogenen Daten zu verlangen.
          </p>
          <p>
            Dies können Sie dem Auftragsverarbeiter IT.Niedersachsen schriftlich anzeigen.
          </p>
          <p class="text-underline">Recht auf Datenübertragbarkeit:</p>
          <p>
            Sie haben – vorbehaltlich der nachfolgenden Regelungen – das Recht, die Übertragung der Sie betreffenden Daten 
            in einem strukturierten, gängigen und maschinenlesbaren Format zu verlangen. Die Daten werden in einem XML Standard 
            an die zuständigen Behörden übermittelt, dabei wird auch eine Leseversion in PDF generiert. 
            Dies geschieht auf einem gesicherten Kanal nach OSCI. Das Recht auf Datenübertragung beinhaltet das Recht zur Übermittlung 
            der Daten an einen anderen Verantwortlichen. Auf Verlangen werden – soweit technisch möglich – die Daten daher direkt 
            an einen von der betroffenen Person benannten oder noch zu benennenden Verantwortlichen übermittelt. 
            Das Recht zur Datenübertragung besteht nur für von der betroffenen Person bereitgestellte Daten und setzt voraus, 
            dass die Verarbeitung auf Grundlage einer Einwilligung oder zur Durchführung eines Vertrages erfolgt und mithilfe 
            automatisierter Verfahren durchgeführt wird.
          </p>
          <p class="text-underline">Recht auf Beschwerde:</p>
          <p>
            Wenn Sie der Auffassung sind, dass wir Ihrem Anliegen nicht oder nicht in vollem Umfang nachgekommen sind, 
            können Sie bei der zuständigen Datenschutzaufsichtsbehörde Beschwerde einlegen.
          </p>
          <p>
            Der/Die Landesbeauftragte für den Datenschutz Niedersachsen<br>
            Prinzenstraße 5<br>
            30159 Hannover<br>
            E-Mail: <a href="mailto:poststelle@lfd.niedersachsen.de" class="mail-link">poststelle@lfd.niedersachsen.de</a>
          </p>
          <p class="text-underline">Verpflichtung der Bereitstellung:</p>          
          <p>
            Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. 
            Sie ist allerdings für die sachgerechte Bearbeitung Ihrer Eingabe erforderlich. 
            Mögliche Folge der Nichtbereitstellung ist eine Einstellung der Bearbeitung.
          </p>
          <p class="text-underline">Widerspruch:</p>
          <p>
            Sie können der weiteren Verarbeitung Ihrer Daten jederzeit aus Gründen, die sich aus Ihrer besonderen Situation ergeben, 
            im Rahmen der Voraussetzungen des Art. 21 DSGVO formlos per E-Mail an <a href="mailto:pressestelle@ml.niedersachsen.de" class="mail-link">pressestelle@ml.niedersachsen.de</a> bzw. 
            <a href="mailto:unternehmensportal@ml.niedersachsen.de" class="mail-link">unternehmensportal@ml.niedersachsen.de</a> widersprechen. Der Widerspruch führt zur Löschung der erhobenen Nutzerdaten.
          </p>
          <p>
            Allerdings sind die erhobenen Daten für die Nutzung der Antragsverwaltung zwingend notwendig. 
            Bei gewünschtem Widerspruch der Datenverarbeitung ist die Nutzung der Antragsverwaltung nicht möglich. 
            Sie können sich jedoch die Antragsmuster herunterladen und konventionell der zuständigen Behörde übermitteln.
          </p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim ab dolorum perferendis tenetur itaque unde. 
            Laudantium, nemo quas. Officiis minus quas dolorem, itaque non fuga a odit quia explicabo nobis.</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",

  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
  .privacy-site{
    margin-left: 15px;
    margin-bottom: 50px;
  }
}
.privacy-site{
  text-align: justify;
  .privacy-content *:not(.headline) {
    line-height: 1.5em !important;
  }
}
.subheadline{
  margin-bottom: 15px;
  margin-top: 15px;
}
p{
  word-wrap: break-word;
  max-width: 68ch;
}
.link-style{
  text-decoration: underline;
}
.text-underline{
  text-decoration: underline;
}
  

</style>

