const LanguageModule = {
    state: () => ({
        language: { locale: "de-DE", language: "deutsch", alt: "deutsche Flagge Menü" },
        languages: [
          { locale: "de-DE", language: "deutsch", alt: "deutsche Flagge Menü" },
          { locale: "en-EN", language: "english", alt: "english flag menu" },     
        ]
    }),
    mutations: {

    },
    getters: {
        languages(state){
            return state.languages
        },

        getLanguage: state =>(language)=>{
            return state.languages.find(obj => obj.locale === language)
        }
    },
    actions: {   
    }
}

export default LanguageModule