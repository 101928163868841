<template>
  <div class="dropdown" v-if="!mobileViewLanguage">
    <button @click="showDropdown" class="dropbtn" :class="{ active: isActive }">
      <img
        :alt="language.alt"
        :src="require(`@/assets/${language.locale}.jpg`)"
      />
    </button>
    <div id="showLanguages" class="dropdown-content">
      <div
        v-for="lang in languages"
        :key="lang"
        :value="lang"
        class="dropdown-element"
        tabindex="0"
        @keydown.enter="setLocale(lang, true)"
        @click="setLocale(lang, true)"
      >
        <img :alt="lang.alt" :src="require(`@/assets/${lang.locale}.jpg`)" />
        <span class="language">{{ lang.language }}</span>
      </div>
    </div>
  </div>
  <span 
    v-if="mobileViewLanguage"
  >
    <span
      v-for="lang in languages"
      :key="lang"
      :value="lang"
      @keydown.enter="setLocale(lang, true)"
      @click="setLocale(lang, true)"
    >
      <a class="language-choose"
        ><img
          :alt="lang.alt" 
          :src="require(`@/assets/${lang.locale}.jpg`)"
        />
        {{ lang.language }}</a
      >
    </span>
  </span>
</template>

<script>
export default {
  name: "LanguageSwitcher",

  props: {
    mobileViewLanguage: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      languages: this.$store.getters["language/languages"],
      isActive: false,
    };
  },

  mounted() {
    let languageOption = true;
    while (languageOption) {
    
      // LocalStorage
      if (languageOption) {
        const localStorageLanguage = localStorage.language;
        if (localStorageLanguage !== "undefined" && localStorageLanguage) {
          // get locale object
          const locale =
            this.$store.getters["language/getLanguage"](localStorageLanguage);
          // set locale object
          this.setLocale(locale);
          // set selected language
          this.selected = locale;
          // end while
          languageOption = false;
        } else {
          // Set default language german
          this.setLocale(this.$store.state.language.language);
           // end while
          languageOption = false;
        }
      }

      // Browser
      if (languageOption) {
        this.getBrowserLanguage();
      }
     
      // end while
      languageOption = false;
    }
  },

  methods: {
    showDropdown() {
      document.getElementById("showLanguages").classList.toggle("show");
      this.isActive = !this.isActive;
    },

    setLocale(locale, clicked) {
      localStorage.setItem("language", locale.locale);
      this.$root.$i18n.locale = locale.locale;
      this.$store.state.language.language = locale;
      if (clicked) {
        this.showDropdown();
      }
      document.documentElement.setAttribute("lang", locale.locale === 'de-DE' ? 'de' : 'en')
    },

    getBrowserLanguage() {
      const browserLanguage = navigator.language.match("en");
      // english
      if (browserLanguage) {
        // modify browserLanguage to match i18 language key/JSON
        const modifiedBrowserLanguage = `${browserLanguage[0]}-EN`;
        // set language
        this.$root.$i18n.locale = modifiedBrowserLanguage;
        this.selected = this.$store.getters["language/getLanguage"](
          modifiedBrowserLanguage
        );
        // german
      } else {
        // set language
        this.$root.$i18n.locale = navigator.language;
        this.selected = this.$store.getters["language/getLanguage"](
          navigator.language
        );
      }
    },
  },

  computed: {
    language() {
      return this.$store.getters["language/getLanguage"](
        this.$i18n.locale)
    },
  },
};
</script>
<style lang="scss" >
/* Dropdown Button */
.dropbtn {
  width: auto;
  border: none;
  cursor: pointer;
  background-color: transparent !important;
  img {
    width: auto;
    height: 12px;
    margin-top: 2px;
  }
  span {
    position: relative;
    top: -2px;
  }
}

.dropbtn.active {
  background-color: #878787 !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  top: -2px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 23px;
  left: 7px;
  min-width: 80px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.4);
  z-index: 1;

  .dropdown-element {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px 5px;
    border-bottom: 1px dotted #878787;

    img {
      width: auto;
      height: 12px;
    }

    .language::after {
      display: none !important;
    }
  }
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-element:hover {
  background-color: #878787;

  .language {
    color: #fff;
  }
}

/* Show the dropdown menu */
.show {
  display: block;
  background:white;
}
</style>


