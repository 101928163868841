import { 
  faFileCsv, 
  faFileExcel, 
  faFileWord, 
  faFilePdf, 
  faFile, 
  faBook, 
  faTag, 
  faEnvelope, 
  faCubes, 
  faSearch, 
  faArrowRightFromBracket, 
  faCheck, 
  faLink, 
  faFileCircleCheck, 
  faFileCircleXmark, 
  faChartLine, 
  faXmark, 
  faArrowUpRightFromSquare,
  faArrowUpFromBracket 
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  faFileCsv, 
  faFileExcel, 
  faFileWord, 
  faFilePdf, 
  faFile, 
  faBook, 
  faTag, 
  faEnvelope, 
  faCubes, 
  faSearch, 
  faArrowRightFromBracket, 
  faCheck, 
  faLink, 
  faFileCircleCheck, 
  faFileCircleXmark, 
  faChartLine, 
  faXmark, 
  faArrowUpRightFromSquare,
  faArrowUpFromBracket 
}

export default icons;

