import { createApp } from 'vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { versionClient } from "@/api/version";
import App from './App.vue'
import router from './router'
import store from './store-modules'
import PrimeVue from 'primevue/config'; // This imports the PrimeVue library.
import i18n from './i18n'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip';
import Keycloak from "keycloak-js";
import axios from 'axios'
import icons from './icons.config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css'; // You can choose the theme you prefer
import 'primeicons/primeicons.css';
import { UploadType } from './utils/enum/UploadType';
import { UploadDataDto } from './utils/dto/UploadDataDto';


let externalConfig;
axios.get('/config.json').then((res) => {
    externalConfig = res.data

    // External configurations values
    store.state.version.externalConfig = externalConfig;
    versionClient.configuration();
})


// Vue Fontawesom
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(icons);


setTimeout(() => {
    const keycloakConfig = store.getters["version/config"];

    const initOptions = {
        url: keycloakConfig.url,
        realm: keycloakConfig.realm,
        clientId: keycloakConfig.client,
    };

    const _keycloak = new Keycloak(initOptions);
    store.state.keycloak.keycloak = _keycloak;

}, 1000);

export const app = createApp(App)
.use(i18n)
.use(store)
.use(router)
.use(PrimeVue, { ripple: true })
.use(ConfirmationService)
.use(ToastService)
.directive('tooltip', Tooltip)
.component("font-awesome-icon", FontAwesomeIcon)

console.log("vue app: ", app)

// Register globally in Vue 3
app.config.globalProperties.$UploadType = UploadType;
app.config.globalProperties.$UploadDataDto = UploadDataDto;

app.mount('#app')

