<template>
  <header>
    <div class="section headnav mainpage">
      <ul>
        <li>
          <div class="backtomain">
            <a :href="safeHref" target="_blank" tabindex="0">{{ $t("header.backtomain") }}</a>
          </div>
        </li>
        <li style="margin-right: 0.8rem;">
          <div>
            <div>
              <a href="https://www.ml.niedersachsen.de/startseite/" target="_blank" tabindex="0" rel=noopener>ML
                Niedersachsen</a>
            </div>
          </div>
        </li>
        <li>
          <div class="serviceAccount">
            <a href="https://mein-unternehmenskonto.de/mup/start">{{
              $t("header.serviceAccount")
            }}</a>
          </div>
        </li>
        <li>
          <div class="management">
            <router-link to="/managementPortal">
              Management
            </router-link>
          </div>
        </li>
        <!--languageSwitcher rausgenommen, da kein English-Switcher erstmal gebraucht-->
        <!-- <li style="margin-right: 0.5rem;" class="haschildren bcola0" @click="showDropdown('', $event)">
          <LanguageSwitcher />
        </li> -->
        <li>
          <div>
            <span class="layoutchange1" tabindex="0" role="button" @keydown.enter="toggleState('accessibileFontSize')"
              @click="toggleState('accessibileFontSize')">Schrift {{shrinkTextSize ? 'verkleinern':'vergrößern'}}</span>
          </div>
        </li>
      </ul>
      <UserProfile v-if="$route.name === 'ManagementPortal'" />
      <CompanyProfile v-if="companyAccountIsLoggedIn && $route.name === 'UploadDocuments'" />
    </div>
    <div class="section logorow">
      <a class="logoadd" href="/" tabindex="0"><img class="logo" src="@/assets/niedersachsen-logo.png"
          alt="Unternehmensportal für den gesundheitlichen Verbraucherschutz Niedersachsen" width="441px"
          height="51px" /></a>
      <a :href="safeHref" title="Link zur Startseite"><img src="@/assets/niedersachsen.png" width="222" height="55"
          class="ndslogo" alt="Niedersachsen Logo" /></a>
      <span class="klar blink"><img src="@/assets/klar.png" alt="Niedersachen klar Logo" /></span>
    </div>
    <div class="section menuerow group" id="navbar" :class="{ fixed: isFixed }">
      <div class="mobinav">
        <span class="klar blink"><img src="@/assets/klar.png" alt="Niedersachen klar Logo" /></span><img
          src="@/assets/niedersachsen.png" width="222" height="55" class="ndslogo" alt="Niedersachsen Logo" />
        <router-link to="/">
          <a class="home" aria-label="zur Startseite"></a>
        </router-link>
        <div class="hamburg" @click="showDropdown('mobile', $event)">
          <em class="pi pi-bars"></em>
        </div>
      </div>
      <div class="ds-menu2" :class="showDropdownMobile ? 'mobile' : ''">
        <ul>
          <li class="home" role="img" aria-label="zurück zur Startseite">
            <router-link to="/">
              <a aria-label="zurück zur Startseite"></a>
            </router-link>
          </li>
          <li class="haschildren showDropDown" tabindex="0" :class="[
            showDropdownCompanies ? 'aktiv' : '',
            { currentSiteActive: active_el == 1 },
          ]" @keydown.enter="showDropdown('companies', $event)" @click="
  showDropdown('companies', $event);
active(1);
">
            <div>
              <span>{{ $t("header.navigation.company.name") }}</span>
            </div>
            <ul class="l1 dropdown-submenu" :class="showDropdownCompanies ? 'lastaktivchild' : ''"
              :style="showDropdownCompanies ? dropdown : ''" @click="resetMobileHamburger()">
              <li class="">
                <router-link to="/Verwaltungsleistungen" class="submenu-link" @keydown.enter="showDropdown">
                  {{
                    $t("header.navigation.company.listOne")
                  }}
                </router-link>
              </li>
              <li class="">
                <router-link to="/InformationPortal" class="submenu-link" @keydown.enter="showDropdown">
                  Informationsportal
                </router-link>
              </li>
              <li class="">
                <router-link to="/managementPortal" class="submenu-link" @keydown.enter="showDropdown">
                  Management
                </router-link>
              </li>
              <!--Remove 'style="display: none;"' later after "Datenaustausch"-page is ready in Module3-->
              <li>
                <router-link to="/dataExchange" class="submenu-link" @keydown.enter="showDropdown">
                  Datenaustausch
                </router-link>
              </li>
            </ul>
          </li>
          <li @click="showDropdown">
            <router-link to="/FAQ">
              <span>FAQ</span>
            </router-link>
          </li>
          <li @click="showDropdown" v-show="$i18n.locale === 'de-DE'">
            <router-link to="/LeichteSprache">
              <span>{{ $t("header.navigation.simpleLanguage") }}</span>
            </router-link>
          </li>
          <li class="monly" @click="showDropdown">
            <router-link to="/Impressum">
              <a href="" target="_self">{{ $t("footer.imprint") }}</a>
            </router-link>
          </li>
          <li @click="showDropdown">
            <router-link to="/Kontakt">
              <span>{{ $t("footer.contact") }}</span>
            </router-link>
          </li>
          <li class="monly" @click="showDropdown">
            <router-link to="/Datenschutz">
              <span>{{ $t("footer.privacy") }}</span>
            </router-link>
          </li>
          <li class="monly" @click="showDropdown">
            <router-link to="/Barrierefreiheit">
              <span>{{ $t("footer.accessibility") }}</span>
            </router-link>
          </li>

          <li class="haschildren mobitools">
            <div @click="showDropdown('mobileEinstellungen', $event)">
              <span>{{ $t("header.settings.title") }}</span>
            </div>
            <ul class="l1" :style="showDropdownMobileEinstellungen ? dropdown : ''">
              <li class="haschildren sprache">
                <div @click="showDropdown('mobileEinstellungenLanguages', $event)">
                  <span>{{ $t("header.settings.language") }}</span>
                </div>
                <ul class="l1" :style="showDropdownMobileEinstellungenLanguages ? dropdown : ''
                  ">
                  <li @click="showDropdown">
                    <LanguageSwitcher v-if="false" :mobileViewLanguage="true" />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="close" @click="showDropdown('mobile', $event)">
          <em class="pi pi-times"></em>
        </div>
        <div class="ndslogo"></div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSwitcher from "@/components/header/LanguageSwitcher.vue";
import UserProfile from "@/components/header/UserProfile.vue";
import CompanyProfile from "@/components/header/CompanyProfile.vue";


export default {
  name: "Header",

  components: {
    LanguageSwitcher,
    UserProfile,
    CompanyProfile
  },

  data() {
    return {
      showDropdownDepartment: false,
      showDropdownServices: false,
      showDropdownCompanies: false,
      showDropdownMobile: false,
      showDropdownMobileEinstellungen: false,
      showDropdownMobileEinstellungenLanguages: false,
      shrinkTextSize: false,

      active_el: 0,

      viewportWidth: null,

      isChanged: false,

      isFixed: false,

      accessibilityStates: {
        accessibileFontSize: false,
        greyscaled: false,
      },
    };
  },

  created() {
    window.addEventListener("pageshow", this.handleResize);
    window.addEventListener("resize", this.handleResize);
  },

  async mounted() {
    if (localStorage.getItem("accessibility-settings")) {
      this.accessibilityStates = JSON.parse(
        localStorage.getItem("accessibility-settings")
      );
      for (var state in this.accessibilityStates) {
        if (this.accessibilityStates[state]) this.applyState(state);
      }
    }

    window.addEventListener("scroll", this.onScroll);

// Check is logged-in-company is set
    if (sessionStorage.getItem("logged-in-company")) {
      this.$store.dispatch('loggedInCompany/setLoggedInCompany', JSON.parse(sessionStorage.getItem('logged-in-company')));
    }
// Added event listner to observe session storage
    window.addEventListener('storage', (event) => {
      if (event.key === 'logged-in-company') {
        const newValue = JSON.parse(event.newValue);
        this.$store.dispatch('loggedInCompany/setLoggedInCompany', newValue);
      }
    });
  },

  methods: {
    active(el) {
      this.active_el = el;
    },

    isSafeURL(url) {
      // Basic check to avoid 'javascript:' protocol. You might need more comprehensive checks.
      return !url.startsWith('javascript:');
    },
    onScroll() {
      window.document.onscroll = () => {
        let navBar = document.getElementById("navbar");
        if (window.scrollY > navBar.offsetTop) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      };
    },
    handleResize() {
      this.viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;

      if (this.viewportWidth < 765) {
        //on mobile
        document.body.classList.remove("desktop");
        document.body.classList.add("mobil");
      } else {
        //not on mobile
        document.body.classList.remove("mobil");
        document.body.classList.add("desktop");
      }
    },
    toggleState(state) {
      this.accessibilityStates[state] = !this.accessibilityStates[state];
      this.applyState(state);
    },

    applyState(state) {
      if (state === "accessibileFontSize") {
        this.accessibilityStates[state]
          ? document.body.classList.add("accessibility-font")
          : document.body.classList.remove("accessibility-font");

          this.shrinkTextSize = !this.shrinkTextSize
      }
    },

    resetMobileHamburger() {
      this.showDropdownMobile = false;
    },

    showDropdown(element) {
      // Refactor the dropdown booleans
      let reset = false;

      switch (element) {
        case "department": {
          // close other dropdowns
          this.showDropdownServices = reset;
          this.showDropdownCompanies = reset;
          this.showDropdownLanguages = reset;
          this.showDropdownMobile = reset;
          this.showDropdownMobileEinstellungen = reset;
          this.showDropdownMobileEinstellungenLanguages = reset;

          this.showDropdownDepartment = !this.showDropdownDepartment;

          break;
        }
        case "services": {
          // close other dropdowns
          this.showDropdownDepartment = reset;
          this.showDropdownCompanies = reset;
          this.showDropdownLanguages = reset;
          this.showDropdownMobile = reset;
          this.showDropdownMobileEinstellungen = reset;
          this.showDropdownMobileEinstellungenLanguages = reset;

          this.showDropdownServices = !this.showDropdownServices;
          break;
        }
        case "companies": {
          // close other dropdowns
          this.showDropdownDepartment = reset;
          this.showDropdownServices = reset;
          this.showDropdownLanguages = reset;
          this.showDropdownMobileEinstellungen = reset;
          this.showDropdownMobileEinstellungenLanguages = reset;

          this.showDropdownCompanies = !this.showDropdownCompanies;
          document.querySelector(".showDropDown").classList.toggle("aktiv");
          break;
        }
        case "languages": {
          // close other dropdowns
          this.showDropdownDepartment = reset;
          this.showDropdownServices = reset;
          this.showDropdownCompanies = reset;
          this.showDropdownMobile = reset;
          this.showDropdownMobileEinstellungen = reset;
          this.showDropdownMobileEinstellungenLanguages = reset;

          this.showDropdownLanguages = !this.showDropdownLanguages;
          break;
        }
        case "mobile": {
          this.showDropdownMobile = !this.showDropdownMobile;

          document.querySelector(".mobil .mobinav").classList.toggle("aktiv");
          break;
        }
        case "mobileEinstellungen": {
          // close other dropdowns
          this.showDropdownDepartment = reset;
          this.showDropdownServices = reset;
          this.showDropdownCompanies = reset;
          this.showDropdownLanguages = reset;
          this.showDropdownMobileEinstellungenLanguages = reset;

          this.showDropdownMobileEinstellungen =
            !this.showDropdownMobileEinstellungen;

          document.querySelector(".mobitools").classList.toggle("aktiv");
          break;
        }

        case "mobileEinstellungenLanguages": {
          this.showDropdownMobileEinstellungenLanguages =
            !this.showDropdownMobileEinstellungenLanguages;

          break;
        }

        default: {
          // reset all dropdowns
          this.showDropdownDepartment = reset;
          this.showDropdownServices = reset;
          this.showDropdownCompanies = reset;
          this.showDropdownLanguages = reset;
          this.showDropdownMobile = reset;
          this.showDropdownMobileEinstellungen = reset;
          this.showDropdownMobileEinstellungenLanguages = reset;
        }
      }
    },
  },

  computed: {
    dropdown() {
      return {
        display: "block !important",
        height: "auto !important",
      };
    },

    safeHref() {
      const hrefValue = this.$t('header.backtomainlink');

      // Check if hrefValue is safe. This is a simplistic check and you might need more robust checking.
      if (this.isSafeURL(hrefValue)) {
        return hrefValue;
      }

      // Return a default or safe value if the check fails.
      return '/';
    },

    ...mapGetters('loggedInCompany', ['isCompanyLoggedIn']),

    companyAccountIsLoggedIn() {
      return this.isCompanyLoggedIn;
    }
  },

  watch: {
    accessibilityStates: {
      handler() {
        // Set localStorage of the browser
        localStorage.setItem(
          "accessibility-settings",
          JSON.stringify(this.accessibilityStates)
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/header.scss";

@media screen and (max-width: 768px) {
  @import "@/styles/header-mobile.scss";

  .menuerow.fixed:before {
    left: -10px;
  }

  .ds-menu2.mobile .ndslogo {
    display: none !important;
  }
}

.ds-menu2 .currentSiteActive .showDropDown,
.ds-menu2 .activeChildren {
  background-color: #e50046;
  padding-top: 3px;
  margin-top: -3px;
  box-shadow: 2px 2px 6px #cccccc;
  color: #fff;
}

.accessibility-font,
.accessibility-font .p-component,
.accessibility-font .p-datatable, 
.accessibility-font .p-button,
// for managementportal tabview font
.accessibility-font .p-tabview-header,
.accessibility-font button,
.accessibility-font input,
.accessibility-font .informationsWithSameClusterTitel,
.accessibility-font .selected-by-categories {
  font-size: 2rem !important;
}

.mobile {
  display: block !important;
  height: auto !important;
}

.management {
  margin-right: 10px;
}
</style>