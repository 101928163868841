const PlzModule = {
  state: {
    postalcodes: [],
    orgUnitContact: {},
    oe: "",
    counter: 0,
    searchPostalcodes: [],

    searchResultsCounter: 0,
    searchAttempt: 0,
    isSearching: false,
    companyOrgUnit: []
  },

  mutations: {
    setItems(state, items) {
      state.postalcodes = items.plzOrgUnitDtos;

      if (state.isSearching) {
        state.searchResultsCounter = items.count;
        state.searchAttempt++;
      }
    },

    setPostalCodes(state, items){
      state.searchPostalcodes = items

      if (state.isSearching) {
        state.searchResultsCounter = state.postalcodes.length;
        state.searchAttempt++;
      }
    },

    setOrgUnitContact(state, item) {
      state.orgUnitContact = item;
    },

    setCompanyOrgUnit(state, item) {
      state.companyOrgUnit = item
    },

    save(state, item) {
      state.postalcodes.push(Object.assign({}, item));
    },

    delete(state, item) {
      const index = state.postalcodes.findIndex(
        (postalcode) => postalcode.id === item.id
      );
      state.postalcodes.splice(index, 1);
    },

    update(state, item) {
      const existsAtIndex = state.postalcodes.findIndex(
        (postalcode) => postalcode.id === item.id
      );
      // Replace the object in array
      state.postalcodes[existsAtIndex] = item;
      // Clone the array to trigger a UI update
      state.postalcodes = [...state.postalcodes];
    },

    setCounter(state, item) {
      state.counter = item;
    },

    resetSearch(state){
      state.searchResultsCounter = 0;
      state.searchAttempt = 0;
      state.isSearching = false;
    },

    isSearching(state, item){
      state.isSearching = item;
    }

  },
  getters: {
    postalcodes: (state) => {
      return state.postalcodes;
    },

    searchPostalcodes: (state) => {
      return state.searchPostalcodes;
    },

    organizationUnitContact: (state) => {
      let foundOrgUnit;
      if (state.oe === "ML") {
        foundOrgUnit = state.orgUnitContact.find(
          (org) => org.niOe === state.oe
        );
      } else {
        foundOrgUnit = state.orgUnitContact[0];
      }
      return foundOrgUnit;
    },

    getpostalcode: (state) => (id) => {
      return state.postalcodes.find((postalcode) => postalcode.id === id);
    },

    counter(state) {
      return state.counter;
    },

    searchResultsCounter: (state) => {
      return state.searchResultsCounter;
    },

    getCompanyOrgUnit(state) {
      return state.companyOrgUnit
    }
  },
  actions: {
    setItems({ commit }, items) {
      commit("setItems", items);
    },
    setPostalCodes({ commit }, items) {
      commit("setPostalCodes", items);
    },
    save({ commit }, item) {
      commit("save", item);
    },
    delete({ commit }, item) {
      commit("delete", item);
    },
    update({ commit }, item) {
      commit("update", item);
    },
    setUser({ commit }, item) {
      commit("setUser", item);
    },
    setOrgUnitContact({ commit }, item) {
      commit("setOrgUnitContact", item);
    },
    setCompanyOrgUnit({ commit }, item) {
      commit("setCompanyOrgUnit", item);
    },
    setCounter({ commit }, item) {
      commit("setCounter", item);
    },
  },
};

export default PlzModule;
