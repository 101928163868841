<template>
  <div class="profileButton" @click="showProfile = !showProfile" @keydown.enter="showProfile = !showProfile"
    tabindex="20">
    {{ letters }}
  </div>

  <div v-show="showProfile" class="companyWrapper overlay" tabindex="21">
    <div class="companyContent">
      <div class="companyNameWrapper">
        <div class="companyIcon" tabindex="22">
          {{ letters }}
        </div>
        <div class="companyName" tabindex="23">
          {{ company?.companyName }}
        </div>
      </div>

      <div class="streetWrapper" v-if="company?.address?.street" tabindex="27">
        <div class="title">Straße</div>
        <div class="street">{{ company?.address?.street }}
          {{ company?.address?.houseNumber }}</div>
      </div>

      <div class="plzWrapper" v-if="company?.address?.postalCode" tabindex="27">
        <div class="title">PLZ</div>
        <div class="plz">{{ company?.address?.postalCode }}</div>
      </div>

      <div class="locationWrapper" v-if="company?.address?.location" tabindex="28">
        <div class="title">Ort</div>
        <div class="location">{{ company?.address?.location }}</div>
      </div>

      <div class="emailWrapper" v-if="company?.email" tabindex="27">
        <div class="title">Email</div>
        <div class="email">{{ company?.email }}</div>
      </div>
    </div>

    <Button label="Abmelden" severity="secondary" icon="fa,arrow-right-from-bracket" outlined @click="logout"
      @keydown.enter="logout">
      <template #icon>
        <font-awesome-icon :icon="['fa', 'arrow-right-from-bracket']" class="logout-icon" />
      </template>
    </Button>
  </div>

</template>

<script setup>
import { ref, watch, computed } from "vue";
import Button from "primevue/button";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const showProfile = ref(false)
const letters = ref('')

const company = computed(() => store.getters['loggedInCompany/getLoggedInCompany'])

watch(company, (newCompany) => {
  if (newCompany && newCompany.companyName) {
    letters.value = `${newCompany.companyName.slice(0, 1).toUpperCase()}`;
  }
}, { immediate: true });

// logout logic
const logout = () => {
  showProfile.value = false;
  router.push({ name: 'DataExchange' });
}
</script>

<style lang="scss">
@mixin companyContentRow {
  display: flex;
  flex-wrap: row wrap;
  gap: 25px;
}

@mixin circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e50056;
  background-color: #fff;
  border: 1px solid #e50056;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 35px;
  font-weight: bold;
}

.profile-button {
  @include circle;
  margin-left: auto;
  margin-top: 10px;
  cursor: pointer;
}

.companyWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 70px auto;
  width: 20%;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  gap: 15px;

  .companyContent {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      margin-left: 6px;
      font-weight: bold;
    }

    .companyNameWrapper {
      @include companyContentRow;

      .companyName {
        position: relative;
        top: 10px;
        font-size: 1rem;
        color: black;
      }
    }

    .streetWrapper {
      @include companyContentRow;

      .street {
        margin-left: -5px;
      }
    }

    .plzWrapper {
      @include companyContentRow;

      .plz {
        margin-left: 8px;
      }
    }

    .locationWrapper {
      @include companyContentRow;

      .location {
        margin-left: 12px;
      }
    }

    .emailWrapper {
      @include companyContentRow;

      .email {
        margin-left: -2px;
        word-break: break-word; /* Break long email addresses */
        overflow-wrap: break-word; /* For more robust handling of long strings */
      }
    }
  }

  .logout {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    cursor: pointer;

    align-items: center;
    border: 1px solid grey;
    padding: 5px;
    gap: 50px;
  }
}

.overlay {
  position: absolute;
  width: 18rem;
  top: 0;
  right: 0;
  background: white;
  z-index: 99999;
  -webkit-box-shadow: 2px 0px 15px 5px rgba(186, 186, 186, 0.8);
  box-shadow: 2px 0px 15px 5px rgba(186, 186, 186, 0.8);

  .companyIcon {
    @include circle;
  }
}

.overlay:after {
  content: "";
  position: absolute;
  top: -15px;
  right: 6px;
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}
</style>