<template>
  <div class="grid information">
    <div class="md:col-7 sm:col-12 homeInfobox">
      <h1 class="headline"> {{$t('landingPage.headline')}}</h1>
      <div class="contentText">
        <p>
          {{$t('landingPage.contextText')}}
        </p>
        <ul>
          <li>{{$t('landingPage.listOne')}}</li>
          <li>{{$t('landingPage.listTwo')}}</li>
          <li>{{$t('landingPage.listThree')}}</li>
          <li>{{$t('landingPage.listFour')}}</li>
          <li>{{$t('landingPage.listFive')}}</li>
        </ul>
        <p>
          {{$t('landingPage.contextTextPartTwo')}}
        </p>
        <p>
          {{$t('landingPage.contextTextPartThree')}}
        </p>
        <p>
          {{$t('landingPage.contextTextPartFour')}}
        </p>
      </div>
    </div>
  </div>
  <div class="linkblockWrapper">
    <div class="linkblock" style="cursor: auto !important;">
      <div class="linkblock-headline-wrapper">
        <h3 class="big">{{$t('landingPage.linkBlockCompany.headline')}}</h3>
      </div>
      <p class="linkblock-text">
        {{$t('landingPage.linkBlockCompany.text')}}
      </p>
      <div class="linkblock-links">
        <span class="linkblock-linktext">
          <router-link to="/Verwaltungsleistungen">
            {{ $t('landingPage.link1') }}
          </router-link>
        </span>
        <span class="linkblock-linktext mt-2">
          <router-link to="/InformationPortal">
            {{ $t('landingPage.link2') }}
          </router-link>
        </span>
      </div>      
    </div>
    
    <router-link 
      id="rs-plainLanguageHomepage" 
      class="linkblock" 
      to="/LeichteSprache" 
      v-show="$i18n.locale === 'de-DE'"
    >
      <div class="linkblock-headline-wrapper">
        <h3 class="big">{{$t('landingPage.linkBlockSimpleLanguage.title')}}</h3>
      </div>
      <p class="linkblock-text">{{$t('landingPage.linkBlockSimpleLanguage.description')}}</p>
      <div class="linkblock-clip"><div class="clip right">{{$t('landingPage.more')}}</div></div>
    </router-link>
   
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      showAnnouncement: true,
    };
  },
  async mounted(){
    window.scrollTo(0, 0)
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 720px) and (orientation: portrait){
    .announcement {
        display: none !important;
    }
    .linkblockWrapper{
      position: relative;
      top: 0.75em;
      grid-template-columns: 1fr !important;
      gap: 0 !important;
    }

    .linkblock-links{
      display: contents !important;
    }

    .linkblock-linktext a{
      border-radius: 5px !important;
      position: inherit !important;
      padding: 5px !important;
      width: 28ch !important;
    }
}

@media only screen and (max-width: 760px) and (orientation: landscape){
    .announcement {
        display: none !important;
    }
    .linkblockWrapper{
      position: relative;
      top: -4.25em;
      grid-template-columns: 1fr !important;
      gap: 0 !important;
    }

    .linkblock-links{
      display: contents !important;
    }

    .linkblock-linktext a{
      border-radius: 5px !important;
      position: inherit !important;
      padding: 5px !important;
      width: 28ch !important;
    }
}



.information{
  margin-top: 4.1875em;
  height:23.375em;
  margin-right: 0; 
  margin-bottom: 20px;
  display: contents !important;
}
.homeInfobox {
  margin-left: 0px;
  padding: 0;
  float: left;
  h1 {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.contentText{
  text-align: justify;
  font-size: 1.1em !important;
  line-height: 1.5em !important;
}

.announcement {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0013c;
  margin-bottom: 20px;
  position: relative;
  left: 60px;
  height: 20em;
  .content {
    margin: 10px 20px;
    word-break: break-all;
    .big {
      color: #e0003c;
      display: inline-block;
      text-transform: uppercase;
    }
    p {
      word-break: break-word;
      font-size: 1.1em !important;
      margin: 0 0 1 em;
      line-height: 1.5em;
      a {
        display: block;
        color: #e0003c;
        text-decoration: underline;
      }
    }
  }

  .clip {
    width: 30px;
    height: 30px;
    display: block;
    background-color: #ccc;
    background-position: 140 px 0;
    cursor: pointer;
  }
  .close {
    position: absolute;
    background-color: #e0003c;
    border-radius: 0 0 5px 5px;
    right: 45px;
    top: -4px;
    z-index: 10 !important;
  }
  .clip.close:before {
    content: "x";
    color: #fff;
    font-size: 34px;
    position: relative;
    left: 7px;
    top: -8px;
  }
  .clip.top:after {
    content: "";
    top: 0;
    right: -4px;
    border-color: transparent #90908f;
    border-style: solid;
    border-width: 3px 0 0 4px;
    position: absolute;
  }
}

.linkblockWrapper {
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-top:5em;
  
  .linkblock:hover {
    background-color: #fff;
    border: 1px solid black;
    .clip {
      background-color: #e0003c !important;
      color: #fff;
    }
  }
  .linkblock {
    display: grid;
    grid-template-rows: 4em auto 2em 2em;
    margin-top: 3em;
    padding: 10px 20px 20px 20px;
    position: relative;
    background: #f2f2f2;
    border: 1px solid transparent;
    cursor: pointer;
    z-index: 1;

    .linkblock-links{
      display: contents;

      .linkblock-linktext a{
        border-radius: 3px;
        padding: 5px;
        background-color: #ccc;
        width: 28ch !important;
        text-align: center;
      }

      a:hover{
        background-color: #e0003c !important;
        color: #fff !important; 
        text-decoration: none;
      }
    }

    a {
      position: absolute;
      z-index: 10;
    }

    .linkblock-clip {
      .clip {
        display: block;
        position: absolute;
        background-color: #ccc;
        background-position: 140px 0;
        cursor: pointer;
      }

      .clip.right {
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        border-radius: 5px 0 0 5px;
        bottom: 15px;
        right: -3px;
        width: auto;
        height: auto;
        font-weight: normal;
        padding: 5px 20px 5px 5px;
      }
      .clip.right:after {
        content: "";
        bottom: -5px;
        right: 0;
        border-color: transparent #90908f;
        border-style: solid;
        border-width: 0 0 5px 3px;
        position: absolute;
      }
    }
  }
}
</style>
