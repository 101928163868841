<template>
  <div class="profileButton" @click="showProfile = !showProfile" @keydown.enter="showProfile = !showProfile" tabindex="20">
    {{ letters }}
  </div>
  <div v-show="showProfile" class="userWrapper overlay" tabindex="21">
    <div class="userContent">
      <div class="userNameWrapper">
        <div class="userIcon" tabindex="22">{{ letters }}</div>
        <div class="userName" tabindex="23">{{ user.name }}</div>
      </div>

      <div class="userRoleWrapper">
        <div class="title" tabindex="24">Rolle</div>
        <div class="userRoles" tabindex="25">
          <div v-for="role in user.roles" :key="role" >{{ role }}</div>
        </div>
      </div>

      <!-- Mock 2 
      <LoginMock />
      -->

      <div class="authorityWrapper" v-if="user.oes && user.oes.length " tabindex="26">
        <div class="title">Behörde</div>
        <div class="authority" >{{ user.oes[0].authority }}</div>
      </div>

      <div class="plzWrapper" v-if="user.oes && user.oes.length" tabindex="27">
        <div class="title">PLZ</div>
        <div class="plz">{{ user.oes[0].zipCode }}</div>
      </div>

      <div class="locationWrapper" v-if="user.oes && user.oes.length" tabindex="28">
        <div class="title">Ort</div>
        <div class="location">{{ user.oes[0].location }}</div>
      </div>

      <div class="OEWrapper" v-if="user.oes && user.oes.length" tabindex="29">
        <div class="title">OE</div>
        <div class="oe">{{ user.oes[0].niOe }}</div>
      </div>
    </div>

    <div class="logout" @click="logout" @keydown.enter="logout" tabindex="30">
      <font-awesome-icon
        :icon="['fa', 'arrow-right-from-bracket']"
        class="logoutIcon"
      />
      <span class="logoutText">Abmelden</span>
    </div>
  </div>
</template>

<script>
import { principalClient } from "@/api/principal";

//import LoginMock from "../content/mock/LoginMock.vue";


export default {
  name: "UserProfile",

  components: {
    //LoginMock
  },

  data() {
    return {
      showProfile: false,
      letters: "",
    };
  },

  mounted() {

    setTimeout(async () => {
      await principalClient.userInformation();
    }, 1500);
  },

  computed: {
    user() {
      return this.$store.getters["principal/user"];
    },
  },

  methods: {
    logout() {
      // keycloak logout with and redirect to the homepage
      const basePath = window.location.origin.toString();
      this.$store.state.keycloak.keycloak.logout({
        redirectUri: `${basePath}`,
      });
      this.showProfile = false;
    },
  },

  watch: {
    user(principal) {
      this.letters = `${principal.givenName
        .slice(0, 1)
        .toUpperCase()}${principal.familyName.slice(0, 1).toUpperCase()}`;
    },
  },
};
</script>

<style lang="scss">
@mixin userContentRow {
  display: flex;
  flex-wrap: row wrap;
  gap: 25px;
}

@mixin circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e50056;
  background-color: #fff;
  border: 1px solid #e50056;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 35px;
  font-weight: bold;
}
.profileButton {
  @include circle;
  margin-left: auto;
  margin-top: 10px;
  cursor: pointer;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 70px auto;
  width: 20%;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  gap: 15px;

  .userContent {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      margin-left: 6px;
      font-weight: bold;
    }

    .userNameWrapper {
      @include userContentRow;
      .userName {
        position: relative;
        top: 10px;
        font-size: 1rem;
        color: black;
      }
    }

    .userRoleWrapper {
      @include userContentRow;

      .userRoles {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }

    .authorityWrapper {
      @include userContentRow;

      .authority {
        margin-left: -18px;
      }
    }

    .plzWrapper {
      @include userContentRow;

      .plz {
        margin-left: 8px;
      }
    }

    .locationWrapper {
      @include userContentRow;
      .location {
        margin-left: 11px;
      }
    }

    .OEWrapper{
       @include userContentRow;
      .oe{
        margin-left: 12px;
      }
    }
  }

  .logout {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    cursor: pointer;

    align-items: center;
    border: 1px solid grey;
    padding: 5px;
    gap: 50px;

    .logoutIcon {
      font-size: 1rem;
    }

    .logoutText {
      font-size: 1rem;
    }
  }
}

.overlay {
  position: absolute;
  width: 18rem;
  top: 0;
  right: 0;
  background: white;
  z-index: 99999;
  -webkit-box-shadow: 2px 0px 15px 5px rgba(186, 186, 186, 0.8);
  box-shadow: 2px 0px 15px 5px rgba(186, 186, 186, 0.8);

  .userIcon {
    @include circle;
  }
}

.overlay:after {
  content: "";
  position: absolute;
  top: -15px;
  right: 6px;
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}
</style>