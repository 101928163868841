<template>
  <div :class="{'pathHistory': true, 'home-page': isHomePage}">
    <Breadcrumb :home="home" :model="breadcrumbList" v-if="!isHomePage">
      <template #item="{ item }">
        <router-link :to="item.path">
          {{ $t(item.label) }}
        </router-link>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";

export default {
  name: "RouterPath",

  components: {
    Breadcrumb,
  },

  mounted() {
    this.$router.isReady();
  },

  data() {
    return {
      home: { label: this.$t("breadcrumb.home"), path: "/" },
      breadcrumbs: [],
    };
  },

  computed: {
    breadcrumbList() {
      return this.breadcrumbs;
    },

    isHomePage(){
      return this.$route.path === '/';
    }
  },

  watch: {
    $route() {
      this.breadcrumbs = [];

      // Detailpages
      if (
        this.$route.name === "service" ||
        this.$route.name === "information"
      ) {
        this.breadcrumbs = this.$route.meta.breadcrumb;
        this.breadcrumbs.push({
          // Modify object value - change underscore to whitespace
          label: Object.values(this.$route.params)[0].replace(/_/g, " "),
          path: this.$route.href,
        });
      } else if (this.$route.name !== "home") {
        this.breadcrumbs.push(this.$route.meta.breadcrumb);
      }
    },

    "$i18n.locale"() {
      this.home = { label: this.$t("breadcrumb.home"), path: "/" };
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .pathHistory, .home-page {
    display: block !important;
  }
}

@media only screen and (max-width: 760px) and (orientation: landscape) {
  .pathHistory, .home-page {
    display: block !important;
  }
}

.pathHistory, .home-page {
  margin-bottom: 20px;
}

.home-page {
  margin-bottom: 35px;
}

.pathHistory, .home-page {
  padding-bottom: 5px;
  border-bottom: 1px dotted #000000 !important;

  
  .p-breadcrumb.p-component{
    border: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      a {
        font-size: 0.8em;
        color: #484848;
        hyphens: auto;
        white-space: nowrap;
      }
    }
  }
}

.p-breadcrumb.p-component {
  font-size: 0.9em;
  height: auto !important;

  a {
    word-break: normal !important;
  }
}

.pi-chevron-right:before {
  content: "" !important;
  margin: 0 2px 0 10px;
  border-color: transparent #626262;
  border-style: solid;
  border-width: 0.2em 0 0.2em 0.3em;
  display: block;
  height: 0;
  width: 0;
  position: relative;
  top: 3.5px;
}
</style>