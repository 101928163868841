import CrudApi from "./crudApi";


class VersionApi extends CrudApi{

    async unternehmensportalVersion(){
        await this.apiUnternehmensPortal.get(`${this.apiName}/getBackendVersion`).then((response) => {
            this.store.dispatch(`${this.module}/setUnternehmensportalVersion`, response.data)
         }).catch((error) => {
             this.errorHandling(this.module, error.response);
         })
    }

    async configuration(){
        const discoveryURL = this.store.state.version.externalConfig['discovery-url'];

        // Using the external discoveryURL in the config.json or else use the keycloakConfig URL
        const backendConfigURL = (discoveryURL)? discoveryURL: `${this.apiName}/getConfigurations`;
        await this.apiUnternehmensPortal.get(backendConfigURL).then((response) => {
           this.store.dispatch(`${this.module}/config`, response.data)     
         }).catch((error) => {
             this.errorHandling(this.module, error.response);
         })
    }

    async informationsportalVersion(){
        await this.apiInformationsPortal.get(`/api/infoportal/v1/commons/getBackendVersion`).then((response) => {
            this.store.dispatch(`${this.module}/setInformationsVersion`, response.data)
         }).catch((error) => {
             this.errorHandling(this.module, error.response);
         })
    }
}

export const versionClient = new VersionApi("api/v1/version","version");