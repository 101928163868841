<template>
  <div class="contact-site">
    <div class="md:col-12 sm:col-12">
      <div class="flex flex-column contact-content">

        <h1 class="headline">{{ $t('footer.contact') }}</h1>

        <strong class="subheadline">{{ $t('contact.title1') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <ul>
            <li class="bullet-style">
              E-Mail:
              <a href="mailto:poststelle@ml.niedersachsen.de" class="mail-link">poststelle@ml.niedersachsen.de</a>
              bzw. <a href="mailto:unternehmensportal@ml.niedersachsen.de"
                class="mail-link">unternehmensportal@ml.niedersachsen.de</a>
            </li>
            <li class="bullet-style">Telefon: +49 (0) 511 120 0</li>
          </ul>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          lorem ipsum
        </span>


        <strong class="subheadline">{{ $t('contact.title2') }}</strong>
        <span v-show="$i18n.locale === 'de-DE'">
          <p>
            Stabsstelle Kommunikation, Presse, Bürgerdialog<br>
            im Niedersächsischen Ministerium<br>
            für Ernährung, Landwirtschaft und<br>
            Verbraucherschutz
          </p>
          <p>Calenberger Straße 2, 30169 Hannover</p>
          <ul>
            <li class="bullet-style">
              E-Mail: <a href="mailto:pressestelle@ml.niedersachsen.de"
                class="mail-link">pressestelle@ml.niedersachsen.de</a>
            </li>
            <li class="bullet-style">Telefon: +49 (0) 511 120 2136 bzw. 2386</li>
            <li class="bullet-style">Telefax: +49 (0) 511 120 2382</li>
          </ul>
          <p>
            Anfragen bezüglich des <strong>Datenschutzes</strong> senden Sie bitte an den Datenschutzbeauftragten des ML
            unter
            <a href="mailto:datenschutz@ml.niedersachsen.de" class="mail-link">datenschutz@ml.niedersachsen.de.</a>
          </p>
          <p>
            <strong>Das Niedersächsische Ministerium für Ernährung, Landwirtschaft und Verbraucherschutz im Internet:
            </strong>
            <a href="https://www.ml.niedersachsen.de/startseite/" target="_blank" class="mail-link"
              rel=noopener>www.ml.niedersachsen.de</a>
          </p>
        </span>
        <span v-show="$i18n.locale === 'en-EN'">
          lorem ipsum
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
  .contact-site {
    margin-left: 15px;
    margin-bottom: 50px;
  }
}

.contact-site .contact-content *:not(.headline) {
  line-height: 1.5em !important;
}

p {
  word-wrap: break-word;
  max-width: 68ch;
}

.bullet-style {
  margin-bottom: 15px;
  word-wrap: break-word;
  max-width: 68ch;
}
</style>
